import { useSuspenseQuery } from '@tanstack/react-query';
import { HikeupApi } from '../../../shared/hikeup-api.ts';
import { useSuspenseKeycloak } from '../../../hooks/keycloak/useSuspenseKeycloak.ts';

export const useSuspenseFetchProfileData = () => {
  const [{ keycloak }] = useSuspenseKeycloak();
  return useSuspenseQuery({
    queryKey: ['currentUserProfile'],
    queryFn: async () => {
      const response =
        await HikeupApi.profileController.findProfileOfCurrentUser({
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
          format: 'json',
        });
      return response.data;
    },
  });
};
