import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import './shared/i18nextConfig.ts';
import { Providers } from './Providers.tsx';
import { Router } from './components/router/Router.tsx';
import { TopBar } from './components/topBar/TopBar.tsx';
import { ToastContainer } from 'react-toastify';
import { LoginModal } from './components/shared/LoginModal.tsx';

const Page = () => {
  return (
    <div className="flex flex-col min-h-screen h-screen max-h-screen">
      <TopBar />
      <Router />
      <ToastContainer />
      <LoginModal />
    </div>
  );
};

const ErrorBoundPage = () => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={() => {
        // todo: Redirect doesn't work find better solution
        window.location.href = '/technical-error';
        return null;
      }}
    >
      <Page />
    </ErrorBoundary>
  );
};

function App() {
  return (
    <Providers>
      <ErrorBoundPage />
    </Providers>
  );
}

export default App;
