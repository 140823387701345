import { useSuspenseFetchComments } from '../../hooks/comments/useSuspenseFetchComments.ts';
import { useTranslation } from 'react-i18next';
import { Heading2 } from '../shared/Heading.tsx';
import type { CommentResponseDTO } from '../../__generated__/HikeupApi.ts';
import { FC, Suspense } from 'react';
import { InfiniteLoader } from '../shared/InfiniteLoader.tsx';
import { SingleUserComment } from './SingleUserComment.tsx';

type TrailCommentsProps = {
  trailId: number;
};

const TrailCommentsInner: FC<TrailCommentsProps> = ({ trailId }) => {
  const { t } = useTranslation();
  const { data } = useSuspenseFetchComments(trailId);

  if (!data) {
    return null;
  }

  if (!data.length) {
    return <div className="text-center">{t('COMMENT.noComments')}</div>;
  }

  return (
    <div>
      <Heading2 className="text-center mt-8">
        {t('COMMENT.commentsHeader')}
      </Heading2>
      {data.map((comment) => (
        <SingleUserComment
          key={comment.id}
          comment={comment as Required<CommentResponseDTO>} // todo(@Danielduel): unhack require https://app.clickup.com/t/86bz78p79
        />
      ))}
    </div>
  );
};

export const TrailComments: FC<TrailCommentsProps> = ({ trailId }) => {
  return (
    <Suspense fallback={<InfiniteLoader />}>
      <TrailCommentsInner trailId={trailId} />
    </Suspense>
  );
};
