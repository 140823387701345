import { create } from 'zustand';
import L, { LatLngTuple } from 'leaflet';
import { createSelectors } from '../utils/createSelectors.ts';

type TrailsMapState = {
  map: L.Map | null;
  setMap: (map: L.Map) => void;
  coordinates: LatLngTuple;
  setMapCoordinates: (coordinates: LatLngTuple) => void;
  zoom: number;
  setMapZoom: (zoom: number) => void;
  currentPath: L.Polyline | null;
  setCurrentPath: (currentPath: L.Polyline | null) => void;
};

const defaultCoordinates: LatLngTuple = [53.45, 14.55];
const defaultZoom = 10;

const getIntialDataFromHash = (): [LatLngTuple, number] => {
  const hashString = location.hash.split('#')[1];
  if (!hashString) {
    return [defaultCoordinates, defaultZoom];
  }

  const [_lat, _lng, _zoom] = hashString.split(';');
  const lat = Number(_lat);
  const lng = Number(_lng);
  const zoom = Number(_zoom);
  const coordinates: LatLngTuple = [lat, lng];
  if (coordinates.every((x) => Number.isFinite(x)) && Number.isFinite(zoom)) {
    return [coordinates, zoom];
  }
  return [defaultCoordinates, defaultZoom];
};

const [coordinates, zoom] = getIntialDataFromHash();

const createStore = () =>
  create<TrailsMapState>((set) => ({
    map: null,
    setMap: (map: L.Map) => set({ map }),
    coordinates,
    setMapCoordinates: (coordinates: LatLngTuple) => set({ coordinates }),
    zoom,
    setMapZoom: (zoom: number) => set({ zoom }),
    currentPath: null,
    setCurrentPath: (currentPath: L.Polyline | null) => set({ currentPath }),
  }));

export const useTrailsMapStore = createSelectors(createStore());

export const useEditorMapStore = createSelectors(createStore());
