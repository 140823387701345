import { useCallback, useContext } from 'react';
import L, { LatLngTuple, PolylineOptions } from 'leaflet';
import { MapStoreContext } from '../shared/mapStoreProvider.tsx';

// TODO handle case when map doesn't have to move/zoom/load
// TODO when zoom changes to lower values - render solid line
// TODO recalculate dashArray to be more or less 250m or so per segment
// TODO draw another line with +/- height difference
export const useDrawPath = (options: PolylineOptions) => {
  const useMapStore = useContext(MapStoreContext);
  const { map, currentPath, setCurrentPath } = useMapStore();

  const clearPath = useCallback(() => {
    if (map && currentPath) {
      map.removeLayer(currentPath);
    }
  }, [currentPath, map]);

  const drawPath = useCallback(
    (path: LatLngTuple[]) => {
      clearPath();
      if (map) {
        const newPath = L.polyline(path, options);
        setCurrentPath(newPath);
        map
          // .once('moveend', () => {
          //   afterMapTilesFinishLoading(map, () => newPath.addTo(map));
          // })
          .fitBounds(newPath.getBounds(), {
            duration: 200,
            animate: true,
            padding: [30, 30],
          });
        newPath.addTo(map);
      }
    },
    [clearPath, map, options, setCurrentPath],
  );

  return { drawPath, clearPath };
};
