import { create } from 'zustand';
import { createSelectors } from '../utils/createSelectors.ts';

export type AppTheme = 'light' | 'dark';

type GlobalState = {
  loginModalVisible: boolean;
  setLoginModalVisible: (loginModalVisible: boolean) => void;
  theme: AppTheme;
  setTheme: (theme: AppTheme) => void;
};

const store = create<GlobalState>((set) => ({
  loginModalVisible: false,
  setLoginModalVisible: (loginModalVisible: boolean) =>
    set({ loginModalVisible }),
  theme: 'light',
  setTheme: (theme: AppTheme) => set({ theme }),
}));

export const useGlobalStateStore = createSelectors(store);
