/* eslint-disable @typescript-eslint/no-empty-function */

import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';

export const useSuspenseKeycloak = () => {
  const keycloak = useKeycloak();
  let _resolve = () => {};

  // keycloak.keycloak.onReady - looks like it's quite unsafe to use
  useEffect(() => {
    if (keycloak.initialized) {
      _resolve();
    }
  }, [keycloak.initialized]);

  if (!keycloak.initialized) {
    throw new Promise<void>((resolve) => {
      _resolve = resolve;
    });
  }

  return [keycloak] as const;
};
