import { useToast } from '../../../hooks/baseHooks/useToast.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetch } from '../../../hooks/baseHooks/useFetch.ts';

type ProfileUpdateInput = {
  userId: number;
  requestBody: {
    description: string;
  };
};

export const useUpdateProfile = () => {
  const { successToast, errorToast } = useToast();
  const queryClient = useQueryClient();
  const fetchWithAuth = useFetch();

  return useMutation({
    mutationFn: async ({ userId, requestBody }: ProfileUpdateInput) => {
      const response = await fetchWithAuth(`/hikeup/api/v1/profile/${userId}`, {
        method: 'PUT',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['currentUserProfile'] });
      successToast('PROFILE.updateProfileSuccess');
    },
    onError: (error: Error) => {
      console.log(error);
      errorToast('PROFILE.updateProfileError');
    },
  });
};
