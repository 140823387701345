import { createContext, PropsWithChildren } from 'react';
import {
  useEditorMapStore,
  useTrailsMapStore,
} from '../store/trailsMapStore.ts';

export const MapStoreContext = createContext(useTrailsMapStore);

export const TrailsMapStoreProvider = (props: PropsWithChildren) => {
  return (
    <MapStoreContext.Provider value={useTrailsMapStore}>
      {props.children}
    </MapStoreContext.Provider>
  );
};

export const EditorMapStoreProvider = (props: PropsWithChildren) => {
  return (
    <MapStoreContext.Provider value={useEditorMapStore}>
      {props.children}
    </MapStoreContext.Provider>
  );
};
