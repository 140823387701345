import { Heading1 } from '../../shared/Heading.tsx';
import { useTranslation } from 'react-i18next';

export const PhotoGalleryPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Heading1>{t('PROFILE.photoGallery.header')}</Heading1>
    </div>
  );
};
