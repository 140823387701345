import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateProfile } from '../../hooks/useUpdateProfile.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CommentInput } from '../../../../models/UserComment.ts';
import { Button } from '../../../shared/Button.tsx';

type UserInfoFormProps = {
  userId: number;
  onFinish: () => void;
};

type UserInfoFormModel = {
  description: string;
};

export const UserInfoForm: FC<UserInfoFormProps> = ({ userId, onFinish }) => {
  const { t } = useTranslation();
  const { mutate } = useUpdateProfile();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CommentInput>();
  const onSubmit: SubmitHandler<UserInfoFormModel> = (requestBody) => {
    mutate({ userId, requestBody });
    onFinish();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>{t('PROFILE.dashboard.profileDescription')}</div>
      <textarea
        className="my-2"
        {...register('description', { required: true })}
      />
      {errors.description && (
        <span className="text-red-600">
          {t('PROFILE.profileDescriptionRequired')}
        </span>
      )}
      <div className="flex gap-6">
        <Button type="button" onClick={() => onFinish()}>
          {t('GENERAL.cancel')}
        </Button>
        <Button type="submit">{t('GENERAL.submit')}</Button>
      </div>
    </form>
  );
};
