import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileDashboardTile } from '../ProfileDashboardTile.tsx';
import { Heading1 } from '../../../shared/Heading.tsx';
import { USER_ACTIVITY_MOCK } from './userActivityMock.ts';
import { UserActivityItem } from './UserActivityItem.tsx';
import { ProfileDTO } from '../../../../__generated__/HikeupApi.ts';

type UserActivityTileProps = {
  profileData: ProfileDTO;
};

export const UserActivityTile: FC<UserActivityTileProps> = () => {
  const { t } = useTranslation();

  return (
    <ProfileDashboardTile className="max-h-full overflow-hidden flex flex-col">
      <Heading1>{t('PROFILE.userActivity.header')}</Heading1>
      <div className="overflow-y-scroll">
        {USER_ACTIVITY_MOCK.map((item) => (
          <UserActivityItem key={item.id} userActivity={item} />
        ))}
      </div>
    </ProfileDashboardTile>
  );
};
