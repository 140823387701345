import { useMemo, type FC } from 'react';
import { Rating, type RatingProps } from '@smastrom/react-rating';
import classNames from 'classnames';

const _style = { maxWidth: 180 };

type TrailRatingProps = RatingProps & {
  containerClassName?: string;
  labelClassName?: string;
};

export const TrailRating: FC<TrailRatingProps> = ({
  labelClassName,
  containerClassName,
  ...props
}) => {
  const style = useMemo(
    () => ({
      ..._style,
      ...props.style,
    }),
    [props.style],
  );

  return (
    <div className={classNames('flex', containerClassName)}>
      <Rating spaceBetween="small" {...props} style={style} />
      <span className={classNames('text-lg font-bold ml-2', labelClassName)}>
        ({props.value.toFixed(1)})
      </span>
    </div>
  );
};
