import { Controller, useForm } from 'react-hook-form';
import { DifficultyLevel } from '../shared/DifficultyLevel.tsx';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  CreateTrailFormModel,
  useEditorPathStore,
} from './store/editorPathStore.ts';

export const CreateTrailForm = () => {
  const { t } = useTranslation();
  const { formValue, setFormValue, setFormValid } = useEditorPathStore();
  const {
    register,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<CreateTrailFormModel>({ mode: 'onChange' });
  const currentValue = watch();

  useEffect(() => {
    setFormValid(isValid);
  }, [isValid, setFormValid]);

  useEffect(() => {
    if (
      currentValue.name !== formValue.name ||
      currentValue.difficultyLevel !== formValue.difficultyLevel
    ) {
      setFormValue(currentValue);
    }
  }, [currentValue, formValue, setFormValue]);

  return (
    <form>
      <div className="flex flex-col my-4">
        <span>{t('TRAIL_EDITOR.trailName')}</span>
        <input className="my-2" {...register('name', { required: true })} />
        {errors.name && (
          <span className="text-red-600">
            {t('TRAIL_EDITOR.trailNameRequired')}
          </span>
        )}

        <span>{t('TRAIL_EDITOR.difficultyLevel')}</span>
        <Controller
          control={control}
          name="difficultyLevel"
          rules={{
            validate: (difficulty) => difficulty > 0,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <DifficultyLevel
              value={value}
              isRequired
              onChange={onChange}
              style={{ maxWidth: 180 }}
              onBlur={onBlur}
            />
          )}
        />
        {errors.difficultyLevel && (
          <span className="text-red-600">
            {t('TRAIL_EDITOR.difficultyLevelRequired')}
          </span>
        )}
      </div>
    </form>
  );
};
