import { useTranslation } from 'react-i18next';
import { TrailGeoDetails } from '../../../models/TrailGeoDetails.ts';
import { FC } from 'react';
import { distanceInKm, durationInHours } from '../../../utils/formatter.ts';

type EditorTrailDetailsProps = {
  trailGeoDetails?: TrailGeoDetails;
};

export const EditorTrailDetails: FC<EditorTrailDetailsProps> = ({
  trailGeoDetails,
}) => {
  const { t } = useTranslation();

  if (!trailGeoDetails) {
    return null;
  }

  return (
    <div className="my-6 *:mb-0.5">
      <div>
        {`${t('TRAIL_EDITOR.distance')}: ${distanceInKm(trailGeoDetails.distance)}`}
      </div>
      <div>
        {`${t('TRAIL_EDITOR.duration')}: ${durationInHours(trailGeoDetails.duration)}`}
      </div>
      <div>
        {t('TRAIL_EDITOR.ascent')}: {trailGeoDetails.ascent}m
      </div>
      <div>
        {t('TRAIL_EDITOR.descent')}: {trailGeoDetails.descent}m
      </div>
    </div>
  );
};
