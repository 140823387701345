import { FC } from 'react';
import { Button } from '../shared/Button.tsx';

type MarkerPopupProps = {
  message: string;
  onButtonClick: () => void;
};

export const MarkerPopup: FC<MarkerPopupProps> = ({
  message,
  onButtonClick,
}) => {
  return (
    <Button className="w-32" onClick={onButtonClick}>
      {message}
    </Button>
  );
};
