import { UnauthorizedBecauseNotLoggedIn } from '../../utils/errors.ts';
import { useGlobalStateStore } from '../../store/globalStateStore.ts';

export const useHandleAuthenticationError = () => {
  const { setLoginModalVisible } = useGlobalStateStore();

  const isAuthenticationError = (error: Error) =>
    error === UnauthorizedBecauseNotLoggedIn;
  const showLoginModal = () => setLoginModalVisible(true);

  return { isAuthenticationError, showLoginModal };
};
