import { useSuspenseQuery } from '@tanstack/react-query';
import { CommentOrigin } from '../../models/UserComment.ts';
import { HikeupApi } from '../../shared/hikeup-api.ts';

export const useSuspenseFetchComments = (trailId: number) => {
  return useSuspenseQuery({
    queryKey: ['comments', trailId],
    queryFn: async () => {
      const response = await HikeupApi.commentController.findAll(
        {
          origin: CommentOrigin.TRAIL,
          originId: trailId,
        },
        {
          format: 'json',
        },
      );
      return response.data;
    },
  });
};
