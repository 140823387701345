import { createElement, FC, type HTMLAttributes } from 'react';
import classNames from 'classnames';

export const ProfileDashboardTile: FC<HTMLAttributes<HTMLDivElement>> = (
  props,
) =>
  createElement('div', {
    ...props,
    className: classNames(
      'p-4 bg-neutral-200 bg-opacity-60 rounded-lg backdrop-blur',
      props.className,
    ),
  });
