import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetch } from '../baseHooks/useFetch.ts';
import { useToast } from '../baseHooks/useToast.ts';
import { useHandleAuthenticationError } from '../baseHooks/useHandleAuthenticationError.ts';

export const useAddTrailToFavorites = () => {
  const { successToast, errorToast } = useToast();
  const { isAuthenticationError, showLoginModal } =
    useHandleAuthenticationError();
  const queryClient = useQueryClient();
  const fetchWithAuth = useFetch();

  return useMutation({
    mutationFn: async (trailId: number) => {
      const response = await fetchWithAuth(
        `/hikeup/api/v1/user/favorite/${trailId}`,
        { method: 'POST' },
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['userTrails'] });
      successToast('TRAIL.addToFavoritesSuccess');
    },
    onError: (error: Error) => {
      console.log(error);

      if (isAuthenticationError(error)) {
        showLoginModal();
      } else {
        errorToast('TRAIL.addToFavoritesError');
      }
    },
  });
};
