import { StoreApi, UseBoundStore } from 'zustand';

type UnpackStore<T> = T extends UseBoundStore<StoreApi<infer S>> ? S : never;
type Selectors<State> = { [K in keyof State]: State[K] };
type UnknownStore = UseBoundStore<StoreApi<Record<string, unknown>>>;

export const createSelectors =
  <GenericStore extends UnknownStore>(store: GenericStore) =>
  <ExpectedSelectors extends Selectors<UnpackStore<typeof store>>>() => {
    const ret = {} as ExpectedSelectors;
    const state = store.getState();
    for (const key in state) {
      (ret[key] as unknown) = store((s) => s[key]);
    }
    return {
      ...ret,
      store,
    } as const;
  };
