import { useTranslation } from 'react-i18next';
import {
  Authenticated,
  NotAuthenticated,
} from '../shared/UserAuthenticated.tsx';
import { useKeycloak } from '@react-keycloak/web';
import userProfileImg from '../../assets/mock/profileImgMock.png';
import avatarPlaceholder from '../../assets/avatarPlaceholder.png';
import { useState } from 'react';
import { MenuDropdown } from './MenuDropdown.tsx';
import { AppSettings } from './AppSettings.tsx';

export const TopBarRightSide = () => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();
  const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);
  const [appSettingsOpen, setAppSettingsOpen] = useState(false);

  if (!initialized) {
    return null;
  }

  const name = keycloak.tokenParsed?.given_name;

  return (
    <div className="flex gap-2 items-center mr-2">
      <Authenticated>
        <div>{t('TOP_BAR.greetings', { name })} &nbsp;</div>
        <img
          className="h-12"
          src={userProfileImg}
          alt={t('TOP_BAR.profileImageAlt')}
          onClick={() => setMenuDropdownVisible(true)}
        />
      </Authenticated>
      <NotAuthenticated>
        <img
          className="h-12"
          src={avatarPlaceholder}
          alt={t('TOP_BAR.profileImageAlt')}
          onClick={() => setMenuDropdownVisible(true)}
        />
      </NotAuthenticated>
      {menuDropdownVisible && (
        <MenuDropdown
          onClose={() => setMenuDropdownVisible(false)}
          onSettingsClick={() => setAppSettingsOpen(true)}
        />
      )}
      {appSettingsOpen && (
        <AppSettings onClose={() => setAppSettingsOpen(false)} />
      )}
    </div>
  );
};
