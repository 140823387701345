import L from 'leaflet';
import GreenPin from '../assets/greenPin.svg';
import OrangePin from '../assets/orangePin.svg';
import RedPin from '../assets/redPin.svg';
import BlackPin from '../assets/blackPin.svg';

const createIcon = (iconUrl: string) =>
  L.icon({
    iconUrl,
    iconSize: [30, 48],
    iconAnchor: [15, 48],
  });

export const greenPin = createIcon(GreenPin);

export const orangePin = createIcon(OrangePin);

export const redPin = createIcon(RedPin);

export const blackPin = createIcon(BlackPin);
