import { Icon } from '../shared/Icon.tsx';
import { useToast } from '../../hooks/baseHooks/useToast.ts';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const ShareTrailButton = () => {
  const { t } = useTranslation();
  const { infoToast } = useToast();

  const onShareClick = useCallback(async () => {
    await navigator.clipboard.writeText(window.location.href);
    infoToast('TRAIL.urlCopied');
  }, [infoToast]);

  return (
    <button
      onClick={onShareClick}
      className="hint--right"
      aria-label={t('TRAIL.share')}
    >
      <Icon iconName="Share" />
    </button>
  );
};
