import { Suspense } from 'react';
import { UserInfoTile } from './userInfo/UserInfoTile.tsx';
import { InfiniteLoader } from '../../shared/InfiniteLoader.tsx';
import { useSuspenseFetchProfileData } from '../hooks/useSuspenseFetchProfileData.ts';
import { AchievementsTile } from './AchievementsTile.tsx';
import { UserActivityTile } from './userActivity/UserActivityTile.tsx';
import { CommunityTile } from './CommunityTile.tsx';
import { PhotoGalleryTile } from './PhotoGalleryTile.tsx';

const ProfileDashboardInner = () => {
  const { data } = useSuspenseFetchProfileData();

  return (
    <div className="grid grid-cols-5 grid-rows-auto gap-8 m-8 max-w-screen-2xl">
      <div className="col-span-3">
        <UserInfoTile profileData={data} />
      </div>
      <div className="col-span-3 col-start-1 row-start-2">
        <AchievementsTile profileData={data} />
      </div>
      <div className="col-span-2 row-span-2 col-start-4 row-start-1 h-0 min-h-full">
        <UserActivityTile profileData={data} />
      </div>
      <div className="col-span-3 row-start-3">
        <CommunityTile profileData={data} />
      </div>
      <div className="col-span-2 col-start-4 row-start-3">
        <PhotoGalleryTile profileData={data} />
      </div>
    </div>
  );
};

export const ProfileDashboard = () => {
  return (
    <Suspense fallback={<InfiniteLoader />}>
      <ProfileDashboardInner />
    </Suspense>
  );
};
