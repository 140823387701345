import { useParams } from 'wouter';
import { useTranslation } from 'react-i18next';
import { MenuButton } from '../leftSideBar/menu/shared/MenuButton.tsx';

export const ProfileLeftSideBar = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const dashboardPath = `/profile/${userId}/dashboard`;
  const activityPath = `/profile/${userId}/activity`;
  const achievementsPath = `/profile/${userId}/achievements`;
  const galleryPath = `/profile/${userId}/gallery`;

  return (
    <div className="h-full max-h-screen bg-neutral-100 w-12 flex flex-col">
      <MenuButton
        iconName="Dashboard"
        path={dashboardPath}
        tooltipLabel={t('PROFILE.leftSideBar.dashboard')}
      />
      <MenuButton
        iconName="TrendingUp"
        path={activityPath}
        tooltipLabel={t('PROFILE.leftSideBar.activity')}
      />
      <MenuButton
        iconName="StarRate"
        path={achievementsPath}
        tooltipLabel={t('PROFILE.leftSideBar.achievements')}
      />
      <MenuButton
        iconName="PhotoCamera"
        path={galleryPath}
        tooltipLabel={t('PROFILE.leftSideBar.photoGallery')}
      />
    </div>
  );
};
