import { type FC, Suspense, useEffect, useState } from 'react';
import { useTrailsMapStore } from '../store/trailsMapStore.ts';
import { LatLngBounds } from 'leaflet';
import { useFetchRecommendedTrails } from '../hooks/trail/useFetchRecommendedTrails.ts';
import { InfiniteLoader } from './shared/InfiniteLoader.tsx';
import { TrailListItem } from './shared/TrailListItem.tsx';
import { useTranslation } from 'react-i18next';
import { Heading2 } from './shared/Heading.tsx';
import classNames from 'classnames';

const emptyBounds = new LatLngBounds([
  [0, 0],
  [0, 0],
]);

export const ListOfRecommendedTrailsInner: FC = () => {
  const { t } = useTranslation();
  const { map } = useTrailsMapStore();
  const [mapBounds, setMapBounds] = useState(map?.getBounds());
  const { data, error, isFetching, isLoading } = useFetchRecommendedTrails(
    mapBounds ?? emptyBounds,
  );

  useEffect(() => {
    if (map) {
      map.on('moveend', () => {
        const bounds = map.getBounds();
        setMapBounds(bounds);
      });
    }
  }, [map]);

  if (error) throw error;

  if (isLoading) {
    return (
      <div className="m-8">
        <Heading2 className="mb-8">{t('TRAIL.recommendedTrails')}</Heading2>
        <InfiniteLoader />
      </div>
    );
  }

  if (!data) {
    return <div className="text-center">{t('GENERAL.noData')}</div>;
  }

  if (!data.length) {
    return <div className="text-center">{t('GENERAL.noResults')}</div>;
  }

  return (
    <div className="m-8 relative">
      <Heading2>{t('TRAIL.recommendedTrails')}</Heading2>
      {/*
      I think this doesn't look good when backend instantly gives data back
      <div
        className={classNames('absolute p-6 w-full', {
          invisible: !isFetching,
        })}
      >
        <InfiniteLoader />
      </div>
      */}
      <div
        className={classNames('transition-opacity', {
          'opacity-30': isFetching,
        })}
      >
        {data.map((item) => (
          <TrailListItem key={item.id} trail={item} />
        ))}
      </div>
    </div>
  );
};

export const ListOfRecommendedTrails = () => {
  return (
    <Suspense fallback={<InfiniteLoader />}>
      <ListOfRecommendedTrailsInner />
    </Suspense>
  );
};
