import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: import.meta.env.VITE_KEYCLOAK_URL,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
};

console.info(import.meta.env.VITE_BUILD_ID);
console.info(keycloakConfig);

export const getKeycloak = () => {
  console.info("getKeycloak - start")

  const kc = new Keycloak(keycloakConfig);
  kc.onReady = () => console.info("Keycloak ready");
  kc.onAuthError = () => console.error("Keycloak onAuthError");
  kc.onAuthRefreshError = () => console.error("Keycloak onAuthRefreshError");

  return kc;
};
