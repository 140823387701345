import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../shared/Button.tsx';
import { Rating } from '@smastrom/react-rating';
import { CommentInput, CommentOrigin } from '../../models/UserComment.ts';
import { useAddComment } from '../../hooks/comments/useAddComment.ts';
import { useTranslation } from 'react-i18next';
import { Heading2 } from '../shared/Heading.tsx';

type AddCommentFormProps = {
  trailId: number;
};

export const AddCommentForm = (props: AddCommentFormProps) => {
  const { t } = useTranslation();
  const { mutate } = useAddComment();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CommentInput>();
  const onSubmit: SubmitHandler<CommentInput> = (data) => {
    data.originId = props.trailId;
    data.origin = CommentOrigin.TRAIL;
    mutate(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center m-4">
        <Heading2>{t('COMMENT.addComment')}</Heading2>
        <Controller
          control={control}
          name="rate"
          rules={{
            validate: (rating) => rating > 0,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Rating
              value={value}
              isRequired
              onChange={onChange}
              visibleLabelId="rating_label"
              style={{ maxWidth: 180 }}
              onBlur={onBlur}
            />
          )}
        />

        {errors.rate && (
          <span className="text-red-600">{t('COMMENT.ratingRequired')}</span>
        )}
        <textarea className="my-2 w-1/2" {...register('description')} />
        <Button type="submit">{t('GENERAL.submit')}</Button>
      </div>
    </form>
  );
};
