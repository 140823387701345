import { useSuspenseQuery } from '@tanstack/react-query';
import { HikeupApi } from '../../shared/hikeup-api.ts';
import { useSuspenseKeycloak } from '../keycloak/useSuspenseKeycloak.ts';
import { TrailPreviewDTO } from '../../__generated__/HikeupApi.ts';

export const useSuspenseFetchUserTrails = () => {
  const [{ keycloak }] = useSuspenseKeycloak();

  return useSuspenseQuery({
    queryKey: ['userTrails'],
    queryFn: async () => {
      if (!keycloak.authenticated) {
        return [] as TrailPreviewDTO[];
      }

      const response =
        await HikeupApi.userFavoriteController.findUserFavoriteTrails({
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
          format: 'json',
        });
      return response.data;
    },
  });
};
