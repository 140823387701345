import { ButtonHTMLAttributes, createElement, FC } from 'react';
import classNames from 'classnames';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const MenuItem: FC<ButtonProps> = (props) =>
  createElement(
    'button',
    {
      ...props,
      className: classNames('m-2 underline hover:font-bold', props.className),
    },
    props.children,
  );
