import { useCallback, useContext, useEffect, useRef } from 'react';
import { MapStoreContext } from '../shared/mapStoreProvider.tsx';
import L from 'leaflet';
import { useTrailDrawPathBasedOnSearchParams } from './trail/useTrailDrawPathBasedOnSearchParams.ts';

const shouldInitializeMap = (container: HTMLElement | null): boolean => {
  // Based on leaflet internals
  // https://github.com/Leaflet/Leaflet/blob/1af639c98410189f1fc0cfd8863f97c9d9f6464a/src/map/Map.js#L1088-L1099

  if (!container) return false; // container doesn't have ref yet
  if ('_leaflet_id' in container) return false; // container already initialized
  return true; // clean container, ready for initialization
};

const useMapInitialize = () => {
  const useMapStore = useContext(MapStoreContext);
  const mapContainer = useRef<HTMLDivElement>(null);
  const { setMap, coordinates, setMapCoordinates, zoom, setMapZoom } =
    useMapStore();
  const [lat, lng] = coordinates;
  const mapIntializedRef = useRef(false);
  useTrailDrawPathBasedOnSearchParams();

  const initializeMap = useCallback(() => {
    const mapInstance = L.map(mapContainer.current!).setView([lat, lng], zoom);
    setMap(mapInstance);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(mapInstance);

    mapInstance.options.minZoom = 7;
    mapInstance.options.maxZoom = 18;

    mapInstance.on('moveend', () => {
      const { lat, lng } = mapInstance.getCenter();
      const zoom = mapInstance.getZoom();
      const hash = `#${lat};${lng};${zoom};&`;

      setMapCoordinates([lat, lng]);
      setMapZoom(zoom);

      if (mapIntializedRef.current && location.hash !== hash) {
        location.replace(`${location.pathname}${location.search}${hash}`);
      }
    });

    // TODO(@Danielduel): make it not timedependant
    mapInstance.whenReady(() => {
      setTimeout(() => {
        mapIntializedRef.current = true;
      }, 1000);
    });
  }, []);

  return { initializeMap, mapContainer } as const;
};

export const useMap = () => {
  const useMapStore = useContext(MapStoreContext);
  const { map, coordinates, zoom } = useMapStore();
  const [lat, lng] = coordinates;
  const { initializeMap, mapContainer } = useMapInitialize();

  useEffect(() => {
    if (shouldInitializeMap(mapContainer.current)) {
      initializeMap();
    }
  }, [ initializeMap ]);

  useEffect(() => {
    if (map) {
      map.setView([lat, lng], zoom);
    }
  }, [lat, lng, map, zoom]);

  return { mapContainer, map } as const;
};
