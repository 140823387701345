import { useQuery } from '@tanstack/react-query';
import { NominatimSearchResponse } from '../models/NominatimSearchResponse.ts';

const NOMINATIM_API_URL = 'https://nominatim.openstreetmap.org/search';

export const useGeocoding = (query: string) => {
  return useQuery({
    queryKey: ['geocoding', query],
    enabled: false,
    staleTime: Infinity,

    queryFn: async () => {
      const params = new URLSearchParams();
      params.set('q', query);
      params.set('format', 'jsonv2');
      params.set('polygon_geojson', '1');
      const response = await fetch(`${NOMINATIM_API_URL}?${params.toString()}`);
      const data: NominatimSearchResponse = await response.json();
      return data;
    },
  });
};
