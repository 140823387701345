import { TopBarLeftSide } from './TopBarLeftSide.tsx';
import { TopBarRightSide } from './TopBarRightSide.tsx';

export const TopBar = () => {
  return (
    <div className="w-full h-16 flex justify-between items-center bg-neutral-100 border-neutral-400 border-b box-border">
      <TopBarLeftSide />
      <TopBarRightSide />
    </div>
  );
};
