import { LatLngTuple } from 'leaflet';
import { Heading2 } from '../shared/Heading.tsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PathPointsListProps = {
  items: LatLngTuple[];
};

export const PathPointsList: FC<PathPointsListProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Heading2 className="text-center mt-8">
        {t('TRAIL_EDITOR.pathPoints')}
      </Heading2>
      {items.map((item, index) => {
        const lat = item[0].toFixed(6);
        const lon = item[1].toFixed(6);
        return (
          <div
            className="p-2 my-4 border-solid border-2 border-gray-800 hover:shadow-lg hover:shadow-emerald-700"
            key={`${index}-${lat}-${lon}`}
          >
            {`${index + 1}. [${lat}, ${lon}]`}
          </div>
        );
      })}
    </div>
  );
};
