import appLogo from '../../assets/appLogo.svg';
import { Heading1 } from '../shared/Heading.tsx';
import { Link } from 'wouter';
import { Authenticated } from '../shared/UserAuthenticated.tsx';
import { useTranslation } from 'react-i18next';

export const TopBarLeftSide = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <img className="mx-4" src={appLogo} alt={t('TOP_BAR.appLogoAlt')} />
      <Heading1 className="!mb-0 mr-4">Hike&nbsp;Up</Heading1>
      <Link className="mx-2 underline hover:font-bold" to="/view">
        {t('TOP_BAR.viewButton')}
      </Link>
      <Link className="mx-2 underline hover:font-bold" to="/create">
        {t('TOP_BAR.createButton')}
      </Link>
      <Authenticated>
        <Link className="mx-2 underline hover:font-bold" to="/profile/me">
          {t('TOP_BAR.profileButton')}
        </Link>
      </Authenticated>
    </div>
  );
};
