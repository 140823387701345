import { create } from 'zustand';
import { createSelectors } from '../../../utils/createSelectors.ts';

type ProfileState = {
  userOwnProfile: boolean;
  setUserOwnProfile: (userOwnProfile: boolean) => void;
};

const store = create<ProfileState>((set) => ({
  userOwnProfile: false,
  setUserOwnProfile: (userOwnProfile: boolean) => set({ userOwnProfile }),
}));

export const useProfileStore = createSelectors(store);
