import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { LatLngBounds } from 'leaflet';
import { HikeupApi } from '../../shared/hikeup-api.ts';

export const useFetchRecommendedTrails = (mapBounds: LatLngBounds) => {
  return useQuery({
    queryKey: ['recommendedTrails', mapBounds],
    queryFn: async () => {
      const response =
        await HikeupApi.recommendedTrailController.findRecommendedByArea(
          {
            latA: mapBounds.getNorth(),
            latB: mapBounds.getSouth(),
            lonA: mapBounds.getWest(),
            lonB: mapBounds.getEast(),
          },
          {
            format: 'json',
          },
        );
      return response.data;
    },
    placeholderData: keepPreviousData,
  });
};
