import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileDashboardTile } from '../ProfileDashboardTile.tsx';
import userProfileImg from '../../../../assets/mock/profileImgMock.png';
import { Heading1 } from '../../../shared/Heading.tsx';
import { ProfileDTO } from '../../../../__generated__/HikeupApi.ts';
import { useProfileStore } from '../../store/profileStore.ts';
import { Button } from '../../../shared/Button.tsx';
import { UserInfoForm } from './UserInfoForm.tsx';

type UserInfoTileProps = {
  profileData: ProfileDTO;
};

export const UserInfoTile: FC<UserInfoTileProps> = ({ profileData }) => {
  const { t } = useTranslation();
  const { fullName, description, userId } = profileData;
  const { userOwnProfile } = useProfileStore();
  const [editEnabled, setEditEnabled] = useState(false);

  return (
    <ProfileDashboardTile>
      <div className="flex">
        <img
          className="h-36 ml-4 mr-8"
          src={userProfileImg}
          alt={t('PROFILE.dashboard.profileImageAlt')}
        />
        <div>
          <Heading1>{fullName}</Heading1>
          {editEnabled && userId ? (
            <UserInfoForm
              userId={userId}
              onFinish={() => setEditEnabled(false)}
            />
          ) : (
            <div>
              <div>{description}</div>
              <div className="font-bold *:mx-2">
                <span>Wrocław, Poland</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {userOwnProfile && !editEnabled && (
        <Button
          className="absolute bottom-2 right-2"
          onClick={() => setEditEnabled(true)}
        >
          {t('PROFILE.dashboard.editYourProfile')}
        </Button>
      )}
    </ProfileDashboardTile>
  );
};
