import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { HikeupApi } from '../../shared/hikeup-api.ts';

export const useFetchTrailById = (trailId: number) => {
  return useQuery({
    queryKey: ['trail', trailId],
    queryFn: async () => {
      const response = await HikeupApi.trailController.findById(trailId, {
        format: 'json',
      });
      return response.data;
    },
    placeholderData: keepPreviousData,
  });
};
