import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useGeocoding } from '../hooks/useGeocoding.ts';
import { useTrailsMapStore } from '../store/trailsMapStore.ts';
import L from 'leaflet';
import { Button } from './shared/Button.tsx';
import { InfiniteLoader } from './shared/InfiniteLoader.tsx';
import { useTranslation } from 'react-i18next';
import { Heading2 } from './shared/Heading.tsx';

export const MapSearch = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [geoJsonLayer, setGeoJsonLayer] = useState<L.GeoJSON | null>(null);
  const { data, isSuccess, isFetching, refetch } = useGeocoding(value);
  const { map } = useTrailsMapStore();

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  useEffect(() => {
    if (data?.[0] && map) {
      if (geoJsonLayer) {
        map.removeLayer(geoJsonLayer);
      }
      const geometry = data[0].geojson;
      const newLayer = L.geoJSON(geometry, {
        style: { color: 'darkblue', weight: 2 },
      }).addTo(map);
      setGeoJsonLayer(newLayer);
      const bbox = data[0].boundingbox;
      const southWest = L.latLng(+bbox[0], +bbox[2]);
      const northEast = L.latLng(+bbox[1], +bbox[3]);
      const bounds = L.latLngBounds(southWest, northEast);
      map.fitBounds(bounds);
    }
  }, [data, map]);

  return (
    <div className="m-8">
      <Heading2>{t('TRAIL.searchNominatim')}</Heading2>
      <input
        placeholder={t('GENERAL.searchPlaceholder')}
        type="text"
        value={value}
        onChange={onChange}
      />
      <Button onClick={() => refetch()} className="mt-2">
        {t('GENERAL.search')}
      </Button>
      <div>
        {isFetching ? (
          <InfiniteLoader />
        ) : isSuccess ? (
          <pre>{JSON.stringify(data[0], null, 2)}</pre>
        ) : null}
      </div>
    </div>
  );
};
