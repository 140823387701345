export type CommentInput = {
  origin: CommentOrigin;
  originId: number;
  rate: number;
  description: string;
};

export type UserComment = {
  id: number;
  userId: number;
  fullName: string;
  description: string;
  rate: number;
};

export enum CommentOrigin {
  TRAIL = 0,
}
