import type { CommentResponseDTO } from '../../__generated__/HikeupApi.ts';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatter.ts';
import { TrailRating } from '../shared/TrailRating.tsx';
import avatarPlaceholder from '../../assets/avatarPlaceholder.png';
import { Link } from 'wouter';
import { FC } from 'react';

type SingleUserCommentProps = {
  comment: Required<CommentResponseDTO>; // todo(@Danielduel): unhack require https://app.clickup.com/t/86bz78p79
};

export const SingleUserComment: FC<SingleUserCommentProps> = ({ comment }) => {
  const { t } = useTranslation();
  const { userId, fullName, rate, description } = comment;
  const createdAt = (comment as any).createdAt; // todo(@Danielduel): unhack require https://app.clickup.com/t/86bz78p79
  const pathToUserProfile = `/profile/${userId}`;

  return (
    <div className="border border-neutral-800 rounded-lg p-2 m-4">
      <div className="font-bold">
        <Link
          className="hover:underline flex  items-center"
          to={pathToUserProfile}
        >
          <img className="w-8 h-8" src={avatarPlaceholder} alt="user avatar" />
          <span>{fullName}</span>
        </Link>
      </div>
      <div className="flex items-center">
        <small>{formatDate(createdAt)}</small>
      </div>
      <div className="flex items-center">
        <span className="font-bold">{t('TRAIL.rate')}:&nbsp;</span>
        <TrailRating value={rate ?? 0} readOnly />
      </div>
      <div>{description}</div>
    </div>
  );
};
