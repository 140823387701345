import { useMutation } from '@tanstack/react-query';
import { useFetch } from '../../../hooks/baseHooks/useFetch.ts';
import { CreateTrailInput } from '../../../models/CreateTrailInput.ts';
import { useToast } from '../../../hooks/baseHooks/useToast.ts';
import { useHandleAuthenticationError } from '../../../hooks/baseHooks/useHandleAuthenticationError.ts';

export const useCreateTrail = () => {
  const { successToast, errorToast } = useToast();
  const { isAuthenticationError, showLoginModal } =
    useHandleAuthenticationError();
  const fetchWithAuth = useFetch();

  return useMutation({
    mutationFn: async (trailInput: CreateTrailInput) => {
      const response = await fetchWithAuth('/hikeup/api/v1/trail', {
        method: 'POST',
        body: JSON.stringify(trailInput),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      return data;
    },
    onSuccess: () => {
      successToast('TRAIL_EDITOR.createTrailSuccess');
    },
    onError: (error: Error) => {
      console.log(error);

      if (isAuthenticationError(error)) {
        showLoginModal();
      } else {
        errorToast('TRAIL_EDITOR.createTrailError');
      }
    },
  });
};
