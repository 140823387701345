import { useTranslation } from 'react-i18next';
import { UserActivity } from './userActivityMock.ts';
import { durationInHours } from '../../../../utils/formatter.ts';
import trailThumbnail from '../../../../assets/mock/trailThumbnail.png';

type UserActivityItemProps = {
  userActivity: UserActivity;
};

export const UserActivityItem = ({ userActivity }: UserActivityItemProps) => {
  const { t } = useTranslation();
  const { trailName, completedOn, duration } = userActivity;

  return (
    <div className="bg-neutral-400 rounded-lg p-4 mb-4 flex justify-between">
      <div className="*:mb-1.5">
        <div>
          <span className="font-bold">
            {t('PROFILE.userActivity.trailName') + ': '}
          </span>
          <span>{trailName}</span>
        </div>
        <div>
          <span className="font-bold">
            {t('PROFILE.userActivity.completedOn') + ': '}
          </span>
          <span>{completedOn}</span>
        </div>
        <div>
          <span className="font-bold">
            {t('PROFILE.userActivity.duration') + ': '}
          </span>
          <span>{durationInHours(duration)}</span>
        </div>
      </div>
      <img
        className="w-1/3"
        src={trailThumbnail}
        alt={t('PROFILE.userActivity.imageAlt')}
      />
    </div>
  );
};
