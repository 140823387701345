import { Modal } from './Modal.tsx';
import { useGlobalStateStore } from '../../store/globalStateStore.ts';
import { Button } from './Button.tsx';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Heading1 } from './Heading.tsx';

export const LoginModal = () => {
  const { t } = useTranslation();
  const { loginModalVisible } = useGlobalStateStore();
  const { keycloak } = useKeycloak();

  if (!loginModalVisible) {
    return null;
  }

  return (
    <Modal>
      <div className="flex flex-col items-center p-8">
        <Heading1>{t('LOGIN.loginModalHeader')}</Heading1>
        <div className="my-8 max-w-md text-center">
          {t('LOGIN.loginModalInfo')}
        </div>
        <div className="flex justify-center gap-6">
          <Button onClick={() => keycloak.login()}>
            {t('LOGIN.loginButton')}
          </Button>
          <Button onClick={() => console.log('to be implemented')}>
            {t('LOGIN.registerButton')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
