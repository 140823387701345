import { create } from 'zustand';
import { createSelectors } from '../utils/createSelectors.ts';

type LeftSideBarState = {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
};

const store = create<LeftSideBarState>((set) => ({
  expanded: true,
  setExpanded: (expanded: boolean) => set({ expanded }),
}));

export const useLeftSideBarStore = createSelectors(store);
