import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CommentInput, UserComment } from '../../models/UserComment.ts';
import { useFetch } from '../baseHooks/useFetch.ts';
import { useToast } from '../baseHooks/useToast.ts';
import { useHandleAuthenticationError } from '../baseHooks/useHandleAuthenticationError.ts';

export const useAddComment = () => {
  const { successToast, errorToast } = useToast();
  const { isAuthenticationError, showLoginModal } =
    useHandleAuthenticationError();
  const queryClient = useQueryClient();
  const fetchWithAuth = useFetch();

  return useMutation({
    mutationFn: async (comment: CommentInput) => {
      const response = await fetchWithAuth('/hikeup/api/v1/comment', {
        method: 'POST',
        body: JSON.stringify(comment),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data: UserComment[] = await response.json();
      return data;
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['trail', variables.originId],
      });
      queryClient.setQueryData(
        ['comments', variables.originId],
        (value: UserComment[]) => [...value, data],
      );
      successToast('COMMENT.addCommentSuccess');
    },
    onError: (error: Error) => {
      console.log(error);

      if (isAuthenticationError(error)) {
        showLoginModal();
      } else {
        errorToast('COMMENT.addCommentError');
      }
    },
  });
};
