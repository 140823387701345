import { FC, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { TrailListItem } from './shared/TrailListItem.tsx';
import { useSuspenseFetchUserFavoriteTrails } from '../hooks/favoriteTrails/useSuspenseFetchUserFavoriteTrails.ts';
import { InfiniteLoader } from './shared/InfiniteLoader.tsx';
import { Heading2 } from './shared/Heading.tsx';

export const UserTrailsInner: FC = () => {
  const { t } = useTranslation();
  const { data, error } = useSuspenseFetchUserFavoriteTrails();

  if (error) throw error;

  if (!data) {
    return (
      <div className="m-8">
        <Heading2 className="mb-8">{t('TRAIL.yourTrails')}</Heading2>
        <InfiniteLoader />
      </div>
    );
  }

  if (!data.length) {
    return <div className="text-center">{t('GENERAL.noResults')}</div>;
  }

  return (
    <div className="m-8">
      <Heading2>{t('TRAIL.yourTrails')}</Heading2>
      {data.map((item) => (
        <TrailListItem key={item.id} trail={item} />
      ))}
    </div>
  );
};

export const UserTrails: FC = () => (
  <Suspense fallback={<InfiniteLoader />}>
    <UserTrailsInner />
  </Suspense>
);
