import {
  type MutableRefObject,
  type FC,
  type PropsWithChildren,
  useRef,
  useCallback,
} from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { isUnauthorizedBecauseNotLoggedIn } from '../../utils/errors';
import { Button } from '../shared/Button';
import { Heading1 } from '../shared/Heading';
import { useKeycloak } from '@react-keycloak/web';
import { InfiniteLoader } from '../shared/InfiniteLoader';
import { Translation } from 'react-i18next';

type WithResetErrorBoundary = {
  resetErrorBoundary: () => void;
};

export type ResetErrorBoundaryRef = MutableRefObject<() => void>;

export const ErrorSpinner: FC = () => (
  <ErrorWrapper>
    <InfiniteLoader />
  </ErrorWrapper>
);

const ErrorWrapper: FC<PropsWithChildren> = ({ children }) => (
  <div className="w-full flex items-center flex-col p-2">{children}</div>
);

type PleaseLogInInnerProps = {
  onClick: () => void;
};
export const PleaseLogIn: FC<PleaseLogInInnerProps> = ({ onClick }) => {
  return (
    <ErrorWrapper>
      <Translation>
        {(t) => (
          <>
            <Heading1>
              {t('ERROR.unauthorizedBecauseNotLoggedIn.title')}
            </Heading1>
            <Button onClick={onClick}>
              {t('ERROR.unauthorizedBecauseNotLoggedIn.button')}
            </Button>
          </>
        )}
      </Translation>
    </ErrorWrapper>
  );
};

const PleaseLogInWrapped: FC = () => {
  const { keycloak, initialized } = useKeycloak();
  const onPleaseLogInClick = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  if (!initialized) {
    return <ErrorSpinner />;
  }

  return <PleaseLogIn onClick={onPleaseLogInClick} />;
};

export const FallbackError: FC<WithResetErrorBoundary> = ({
  resetErrorBoundary,
}) => (
  <ErrorWrapper>
    <Translation>
      {(t) => (
        <>
          {t('ERROR.fallbackError.title')}
          <Button onClick={() => resetErrorBoundary()}>
            {t('ERROR.fallbackError.button')}
          </Button>
        </>
      )}
    </Translation>
  </ErrorWrapper>
);

type ErrorBoundaryProps = PropsWithChildren<{
  resetErrorBoundaryRef?: ResetErrorBoundaryRef;
}>;

// eslint-disable-next-line react-refresh/only-export-components
export const useResetErrorBoundaryRef = () => {
  const resetErrorBoundaryRef = useRef(() => void 0);
  return { resetErrorBoundaryRef } as const;
};

export type WithUseResetErrorBoundaryRef = ReturnType<
  typeof useResetErrorBoundaryRef
>;

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({
  children,
  resetErrorBoundaryRef,
}) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ReactErrorBoundary
      onReset={reset}
      fallbackRender={({ resetErrorBoundary, error }) => {
        if (resetErrorBoundaryRef) {
          resetErrorBoundaryRef.current = resetErrorBoundary;
        }

        switch (true) {
          case isUnauthorizedBecauseNotLoggedIn(error):
            return <PleaseLogInWrapped />;
          default:
            return <FallbackError resetErrorBoundary={resetErrorBoundary} />;
        }
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};
