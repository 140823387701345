import { Heading1 } from '../shared/Heading.tsx';
import { useErrorData } from './useErrorData.ts';
import { useTranslation } from 'react-i18next';

export const TechnicalErrorPage = () => {
  const { t } = useTranslation();
  const { errorCode, errorMessage } = useErrorData();

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <Heading1>{t('ERROR.technicalError')}</Heading1>
      <div>{errorCode}</div>
      <div>{errorMessage}</div>
    </div>
  );
};
