export const UnauthorizedBecauseNotLoggedIn = new Error(
  'UnauthorizedBecauseNotLoggedIn',
);

export const isResponseUnauthorizedBecauseNotLoggedIn = (response: Response) =>
  response.status === 401 &&
  response.headers
    .get('www-authenticate')
    ?.startsWith('Bearer error="invalid_token"');

export const isUnauthorizedBecauseNotLoggedIn = (
  error: Error,
): error is typeof UnauthorizedBecauseNotLoggedIn =>
  error === UnauthorizedBecauseNotLoggedIn;

export const UnauthorizedBecauseMissingPermission = new Error(
  'UnauthorizedBecauseMissingPermission',
);
