// TODO: get current locale from react-i18next
/**
 *  @param distance in meters
 **/
export const distanceInKm = (distance: number) => {
  const result = Intl.NumberFormat('pl-PL', {
    maximumFractionDigits: 2,
  }).format(distance / 1000);
  return `${result}km`;
};

/**
 *  Output format: HH:MM
 *  @param duration in seconds
 **/
export const durationInHours = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

const dateFormatter = new Intl.DateTimeFormat('pl-PL', {
  year: 'numeric',
  month: 'numeric',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
});

export const formatDate = (value: Date | string) => {
  const input = typeof value === 'string' ? new Date(value) : value;
  return dateFormatter.format(input);
};
