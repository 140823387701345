import { Heading1 } from '../../shared/Heading.tsx';
import { useEditorPathStore } from '../store/editorPathStore.ts';
import { FC, useCallback } from 'react';
import { PathPointsList } from '../PathPointsList.tsx';
import { TrailGeoDetails } from '../../../models/TrailGeoDetails.ts';
import { useCreateTrail } from '../hooks/useCreateTrail.ts';
import { CreateTrailForm } from '../CreateTrailForm.tsx';
import { useTranslation } from 'react-i18next';
import { EditorActionButtons } from './EditorActionButtons.tsx';
import { EditorTrailDetails } from './EditorTrailDetails.tsx';

type TrailEditorToolbarProps = {
  trailGeoDetails?: TrailGeoDetails;
};

export const TrailEditorToolbar: FC<TrailEditorToolbarProps> = ({
  trailGeoDetails,
}) => {
  const { t } = useTranslation();
  const {
    resetPath,
    pathPoints,
    addPointToPath,
    removePointFromPath,
    pathPointsLayerGroup,
    formValue,
    formValid,
  } = useEditorPathStore();
  const { mutate } = useCreateTrail();

  const resetPathFn = useCallback(() => {
    resetPath();
    pathPointsLayerGroup.clearLayers();
  }, [pathPointsLayerGroup, resetPath]);

  const savePath = useCallback(() => {
    if (formValid && pathPoints.length > 1) {
      const { name, difficultyLevel } = formValue;
      mutate({
        name,
        difficultyLevel,
        position: pathPoints[0],
        coordinates: pathPoints,
      });
      resetPathFn();
    }
  }, [formValid, formValue, mutate, pathPoints, resetPathFn]);

  const closeLoop = useCallback(() => {
    const firstPoint = pathPoints[0];
    addPointToPath([...firstPoint]);
  }, [addPointToPath, pathPoints]);

  const removeLastPoint = useCallback(() => {
    const index = pathPoints.length - 1;
    removePointFromPath(pathPoints[index]);
  }, [pathPoints, removePointFromPath]);

  return (
    <div className="h-full max-h-screen bg-neutral-100 min-w-96 w-1/4 p-8 flex flex-col">
      <Heading1 className="text-center">{t('TRAIL_EDITOR.title')}</Heading1>
      {pathPoints.length ? (
        <>
          <div className="flex-1 mb-6 overflow-y-scroll">
            <CreateTrailForm />
            <PathPointsList items={pathPoints}></PathPointsList>
          </div>
          <div>
            <EditorTrailDetails
              trailGeoDetails={trailGeoDetails}
            ></EditorTrailDetails>
            <EditorActionButtons
              onUndo={removeLastPoint}
              onCloseLoop={closeLoop}
              onReset={resetPathFn}
              onSave={savePath}
            ></EditorActionButtons>
          </div>
        </>
      ) : (
        <div className="text-center my-16 mx-4">{t('TRAIL_EDITOR.hint')}</div>
      )}
    </div>
  );
};
