import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../shared/Modal.tsx';
import { Heading1 } from '../shared/Heading.tsx';
import { LanguageSelector } from './LanguageSelector.tsx';
import { ThemeSelector } from './ThemeSelector.tsx';

type AppSettingsProps = {
  onClose: () => void;
};

export const AppSettings: FC<AppSettingsProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal closeable={true} onClose={onClose}>
      <div className="flex flex-col items-center py-4 px-16">
        <Heading1>{t('TOP_BAR.settings.header')}</Heading1>
        <LanguageSelector />
        <ThemeSelector />
      </div>
    </Modal>
  );
};
