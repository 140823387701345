import { useSuspenseQuery } from '@tanstack/react-query';
import { HikeupApi } from '../../shared/hikeup-api.ts';

export const useSuspenseFetchPins = () => {
  return useSuspenseQuery({
    queryKey: ['pins'],
    queryFn: async () => {
      const response = await HikeupApi.trailController.findAllAsPins(
        {},
        {
          format: 'json',
        },
      );
      return response.data;
    },
  });
};
