import { Redirect, Route, Switch, useParams } from 'wouter';
import { ProfileLeftSideBar } from './ProfileLeftSideBar.tsx';
import { ProfileDashboard } from './dashboard/ProfileDashboard.tsx';
import { useProfileStore } from './store/profileStore.ts';
import { ActivityPage } from './activityPage/ActivityPage.tsx';
import { AchievementsPage } from './achievementsPage/AchievementsPage.tsx';
import { PhotoGalleryPage } from './photoGalleryPage/PhotoGalleryPage.tsx';
import { useEffect } from 'react';

export const ProfilePage = () => {
  const params = useParams();
  const { setUserOwnProfile } = useProfileStore();

  // todo: temporary solution, needs change - probably we should fetch user ID on start
  useEffect(() => {
    setUserOwnProfile(params.userId === 'me');
  }, [params.userId, setUserOwnProfile]);

  return (
    <div className="flex flex-1 max-h-min min-h-min bg-profile-bg bg-center bg-cover">
      <ProfileLeftSideBar />
      <div className="flex-1 flex justify-center h-fit">
        <Switch>
          <Route path="/dashboard" component={ProfileDashboard} />
          <Route path="/activity" component={ActivityPage} />
          <Route path="/achievements" component={AchievementsPage} />
          <Route path="/gallery" component={PhotoGalleryPage} />
          <Route>
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
