import { toast, TypeOptions } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const useToast = () => {
  const { t } = useTranslation();

  const showToast = (key: string, type: TypeOptions) =>
    toast(t(key), { type, position: 'bottom-center' });

  const successToast = (key: string) => showToast(key, 'success');
  const errorToast = (key: string) => showToast(key, 'error');
  const warningToast = (key: string) => showToast(key, 'warning');
  const infoToast = (key: string) => showToast(key, 'info');
  const defaultToast = (key: string) => showToast(key, 'default');

  return { successToast, errorToast, warningToast, infoToast, defaultToast };
};
