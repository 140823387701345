export const preventPropagation = (e: { stopPropagation: () => void }) =>
  e.stopPropagation();

const readAndCreateLocalStorage = (key: string, defaultValue: string) => {
  const read = localStorage.getItem(key);

  if (read === null) {
    localStorage.setItem(key, defaultValue);
    return defaultValue;
  }

  return read;
};

const JSONParseOrPass = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

export const mock = <T>(data: T, configKey: string) => {
  const delayRead = readAndCreateLocalStorage(`${configKey}_delay`, '0');
  const delay = Number.parseInt(delayRead) ?? 0;
  const failRead = readAndCreateLocalStorage(`${configKey}_fail`, 'false');
  const shouldFail = failRead === 'false' ? false : true;
  const failData = JSONParseOrPass(failRead);
  return new Promise<T>((resolve, reject) =>
    setTimeout(() => (shouldFail ? reject(failData) : resolve(data)), delay),
  );
};
