import { useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import L, { layerGroup } from 'leaflet';
import { MarkerPopup } from '../MarkerPopup.tsx';
import { useEditorMapStore } from '../../../store/trailsMapStore.ts';
import { useEditorPathStore } from '../store/editorPathStore.ts';
import { blackPin, greenPin, redPin } from '../../../shared/mapMarkers.ts';
import { useTranslation } from 'react-i18next';

export const useMapMarkers = () => {
  const { map } = useEditorMapStore();
  const {
    pathPoints,
    addPointToPath,
    pathPointsLayerGroup,
    setPathPointsLayerGroup,
  } = useEditorPathStore();
  const [marker, setMarker] = useState<L.Marker | null>(null);
  const { t } = useTranslation();

  const setMapMarker = useCallback(
    (mapEvent: L.LeafletMouseEvent) => {
      if (map) {
        if (marker) {
          map.removeLayer(marker);
        }
        const newMarker = L.marker(mapEvent.latlng).addTo(map);
        setMarker(newMarker);
        const popupContent = document.createElement('div');
        const root = createRoot(popupContent);
        root.render(
          <MarkerPopup
            message={t('TRAIL_EDITOR.addPoint')}
            onButtonClick={() => {
              const { lat, lng } = mapEvent.latlng;
              addPointToPath([lat, lng]);
              map.removeLayer(newMarker);
            }}
          />,
        );
        newMarker.bindPopup(popupContent, { minWidth: 124 }).openPopup();
      }
    },
    [addPointToPath, map, marker],
  );

  useEffect(() => {
    map?.on('click', setMapMarker);
    return () => {
      map?.off('click', setMapMarker);
    };
  }, [map, setMapMarker]);

  useEffect(() => {
    if (map) {
      const newLayerGroup = layerGroup([]);
      pathPoints.forEach((item, index) => {
        let icon = greenPin;
        if (index === 0) {
          icon = redPin;
        } else if (index === pathPoints.length - 1) {
          icon = blackPin;
        }
        L.marker(item, { icon }).addTo(newLayerGroup);
      });
      pathPointsLayerGroup.clearLayers();
      setPathPointsLayerGroup(newLayerGroup);
      newLayerGroup.addTo(map);
    }
  }, [map, pathPoints]);

  return { pathPoints } as const;
};
