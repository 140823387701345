import { useAddTrailToFavorites } from './useAddTrailToFavorites.ts';
import { useRemoveTrailFromFavorites } from './useRemoveTrailFromFavorites.ts';

export const useFavoriteHook = (isFavorite: boolean) => {
  const { mutate: addToFavorites } = useAddTrailToFavorites();
  const { mutate: removeFromFavorites } = useRemoveTrailFromFavorites();

  return {
    isFavorite,
    addToFavorites,
    removeFromFavorites,
  } as const;
};

export type FavoriteHook = ReturnType<typeof useFavoriteHook>;
