import { FC } from 'react';
import { MenuItem } from './MenuDropdownItem.tsx';
import {
  Authenticated,
  NotAuthenticated,
} from '../shared/UserAuthenticated.tsx';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

type MenuDropdownProps = {
  onClose: () => void;
  onSettingsClick: () => void;
};

export const MenuDropdown: FC<MenuDropdownProps> = ({
  onClose,
  onSettingsClick,
}) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  return (
    <div className="absolute top-0 left-0 h-full w-full z-20" onClick={onClose}>
      <div className="p-4 bg-white z-30 absolute top-16 right-0 flex flex-col">
        <MenuItem
          className="text-gray-300"
          onClick={() => console.log('Navigate to Help Center')}
        >
          {t('TOP_BAR.settings.helpCenter')}
        </MenuItem>
        <MenuItem
          className="text-gray-300"
          onClick={() => console.log('Navigate to Regulations')}
        >
          {t('TOP_BAR.settings.regulations')}
        </MenuItem>
        <MenuItem
          className="text-gray-300"
          onClick={() => console.log('Navigate to Send opinion')}
        >
          {t('TOP_BAR.settings.sendOpinion')}
        </MenuItem>
        <MenuItem
          className="text-gray-300"
          onClick={() => console.log('Navigate to Privacy')}
        >
          {t('TOP_BAR.settings.privacy')}
        </MenuItem>
        <MenuItem onClick={onSettingsClick}>
          {t('TOP_BAR.settings.settings')}
        </MenuItem>
        <Authenticated>
          <MenuItem onClick={() => keycloak.logout()}>
            {t('TOP_BAR.logout')}
          </MenuItem>
        </Authenticated>
        <NotAuthenticated>
          <MenuItem onClick={() => keycloak.login()}>
            {t('TOP_BAR.login')}
          </MenuItem>
        </NotAuthenticated>
      </div>
    </div>
  );
};
