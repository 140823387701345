import { type FC } from 'react';
import { Redirect, Route, Switch } from 'wouter';
import { ListOfRecommendedTrails } from '../../ListOfRecommendedTrails';
import { MapSearch } from '../../MapSearch';
import { TrailsSearch } from '../../trailsSearch/TrailsSearch.tsx';
import { UserTrails } from '../../UserTrails';
import {
  ErrorBoundary,
  type WithUseResetErrorBoundaryRef,
} from '../../errorBoundary/ErrorBoundary';

export const Content: FC<WithUseResetErrorBoundaryRef> = ({
  resetErrorBoundaryRef,
}) => (
  <ErrorBoundary resetErrorBoundaryRef={resetErrorBoundaryRef}>
    <Switch>
      <Route path="/recommended" component={ListOfRecommendedTrails} />
      <Route path="/search-nominatim" component={MapSearch} />
      <Route path="/search-trails" component={TrailsSearch} />
      <Route path="/favorite-trails" component={UserTrails} />
      <Route path="/">
        <Redirect to="/recommended" />
      </Route>
    </Switch>
  </ErrorBoundary>
);
