import { createContext, PropsWithChildren, useEffect, useState } from 'react';

export const DevModeContext = createContext(false);

export const DevModeProvider = (props: PropsWithChildren) => {
  const [isDevModeEnabled, setIsDevModeEnabled] = useState(false);

  useEffect(() => {
    console.log('Press F4 to enable dev mode');
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'F4') {
        setIsDevModeEnabled((prevState) => !prevState);
        console.log(`Dev mode ${isDevModeEnabled ? 'disabled' : 'enabled'}`);
      }
    };
    window.addEventListener('keyup', handleKeyPress);
    return () => window.removeEventListener('keyup', handleKeyPress);
  }, [isDevModeEnabled]);

  return (
    <DevModeContext.Provider value={isDevModeEnabled}>
      {props.children}
    </DevModeContext.Provider>
  );
};
