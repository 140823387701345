import {
  type FC,
  type PropsWithChildren,
  useLayoutEffect,
  useState,
} from 'react';
import { preventPropagation } from '../../utils/misc';
import { createPortal } from 'react-dom';
import { Icon } from './Icon';

export type ModalProps = PropsWithChildren<{
  closeable?: boolean;
  onClose?: () => void;
}>;

const ModalShell: FC<ModalProps> = ({ children, closeable, onClose }) => {
  return (
    <div className="absolute top-0 left-0 h-full w-full bg-opacity-20 bg-neutral-800 flex flex-col justify-center items-center z-20">
      <div onClick={preventPropagation} className="bg-white rounded-lg p-6">
        {closeable && (
          <div className="flex justify-end">
            <button onClick={onClose}>
              <Icon iconName="Close" />
            </button>
          </div>
        )}
        <div className="max-h-[80vh] max-w-[80vw] overflow-y-scroll">
          {children}
        </div>
      </div>
    </div>
  );
};

type ModalPortalProps = PropsWithChildren<{ wrapperId?: string }>;

const ModalPortal: FC<ModalPortalProps> = ({
  children,
  wrapperId = 'modal-root',
}) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
    null,
  );

  useLayoutEffect(() => {
    const element = document.getElementById(wrapperId)!;
    setWrapperElement(element);
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};

export const Modal: FC<ModalProps> = ({ children, closeable, onClose }) => {
  return (
    <ModalPortal>
      <ModalShell onClose={onClose} closeable={closeable}>
        {children}
      </ModalShell>
    </ModalPortal>
  );
};
