import { FC } from 'react';
import { ProfileDashboardTile } from './ProfileDashboardTile.tsx';
import achievement1 from '../../../assets/mock/achievement1.png';
import achievement2 from '../../../assets/mock/achievement2.png';
import achievement3 from '../../../assets/mock/achievement3.png';
import achievement4 from '../../../assets/mock/achievement4.png';
import achievement5 from '../../../assets/mock/achievement5.png';
import { ProfileDTO } from '../../../__generated__/HikeupApi.ts';
import { PROFILE_DATA_MOCK } from '../../../mocks/profileDataMock.ts';

type AchievementsTileProps = {
  profileData: ProfileDTO;
};

const mockAchievementsIconMap: Record<string, string> = {
  A1: achievement1,
  A2: achievement2,
  A3: achievement3,
  A4: achievement4,
  A5: achievement5,
};

export const AchievementsTile: FC<AchievementsTileProps> = () => {
  const { achievements } = PROFILE_DATA_MOCK;
  return (
    <ProfileDashboardTile>
      <div className="flex *:flex-1">
        {achievements.slice(0, 5).map((item) => (
          <div
            key={item}
            className="rounded-full border-2 border-gray-900 m-4 flex items-center justify-center"
          >
            <img
              className="h-2/3"
              src={mockAchievementsIconMap[item]}
              alt={item}
            />
          </div>
        ))}
      </div>
    </ProfileDashboardTile>
  );
};
