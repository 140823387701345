import {
  QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useFetch } from '../baseHooks/useFetch.ts';
import { useToast } from '../baseHooks/useToast.ts';

const invalidateTrailRelatedQueries = async (queryClient: QueryClient) => {
  const keysToInvalidate = [
    'userTrails',
    'pins',
    'trails',
    'recommendedTrails',
  ];
  await queryClient.invalidateQueries({
    predicate: (query) =>
      keysToInvalidate.includes(query.queryKey[0] as string),
  });
};

export const useDeleteTrail = () => {
  const { successToast, errorToast } = useToast();
  const queryClient = useQueryClient();
  const fetchWithAuth = useFetch();

  return useMutation({
    mutationFn: async (trailId: number) => {
      const response = await fetchWithAuth(`/hikeup/api/v1/trail/${trailId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return await response.text();
    },
    onSuccess: async () => {
      await invalidateTrailRelatedQueries(queryClient);
      successToast('TRAIL.deleteTrailSuccess');
    },
    onError: (error: Error) => {
      console.log(error);
      errorToast('TRAIL.deleteTrailError');
    },
  });
};
