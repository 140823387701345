import classNames from 'classnames';
import { type ButtonHTMLAttributes, type FC, createElement } from 'react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: FC<ButtonProps> = (props) =>
  createElement(
    'button',
    {
      ...props,
      className: classNames(
        'bg-green-600 hover:bg-green-500 disabled:bg-neutral-500 text-white font-bold py-1 px-2 rounded',
        props.className,
      ),
    },
    props.children,
  );
