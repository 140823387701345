import { MapDebugInfo } from '../debug/MapDebugInfo.tsx';
import { TrailEditorToolbar } from './toolbar/TrailEditorToolbar.tsx';
import { TrailEditorMap } from './TrailEditorMap.tsx';
import { EditorMapStoreProvider } from '../../shared/mapStoreProvider.tsx';
import { useMapMarkers } from './hooks/useMapMarkers.tsx';
import { useTrailDraft } from './hooks/useTrailDraft.ts';

export const TrailEditorPage = () => {
  const { pathPoints } = useMapMarkers();
  const { data } = useTrailDraft(pathPoints);

  return (
    <EditorMapStoreProvider>
      <div className="flex flex-1 max-h-min min-h-min">
        <TrailEditorToolbar trailGeoDetails={data} />
        <TrailEditorMap trailGeoDetails={data} />
      </div>
      <MapDebugInfo />
    </EditorMapStoreProvider>
  );
};
