import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileDashboardTile } from './ProfileDashboardTile.tsx';
import { Heading1 } from '../../shared/Heading.tsx';
import { ProfileDTO } from '../../../__generated__/HikeupApi.ts';

type PhotoGalleryTileProps = {
  profileData: ProfileDTO;
};

export const PhotoGalleryTile: FC<PhotoGalleryTileProps> = () => {
  const { t } = useTranslation();

  return (
    <ProfileDashboardTile>
      <Heading1>{t('PROFILE.photoGallery.header')}</Heading1>
    </ProfileDashboardTile>
  );
};
