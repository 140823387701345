import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading2 } from '../shared/Heading.tsx';
import { AppTheme, useGlobalStateStore } from '../../store/globalStateStore.ts';

export const ThemeSelector = () => {
  const { t } = useTranslation();
  const { theme, setTheme } = useGlobalStateStore();

  const onChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setTheme(event.target.value as AppTheme);
    },
    [setTheme],
  );

  return (
    <div className="my-4 mx-8">
      <Heading2>{t('TOP_BAR.settings.themeHeader')}</Heading2>
      <div>{t('TOP_BAR.settings.selectTheme')}</div>
      <select id="theme" onChange={onChange} value={theme}>
        <option value="light">{t('TOP_BAR.settings.light')}</option>
        <option value="dark">{t('TOP_BAR.settings.dark')}</option>
      </select>
    </div>
  );
};
