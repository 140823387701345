import { Redirect, Route, Switch } from 'wouter';
import { TrailEditorPage } from '../trailEditor/TrailEditorPage';
import { TrailViewPage } from './TrailViewPage';
import { ProfilePage } from '../userProfile/ProfilePage.tsx';
import { TechnicalErrorPage } from '../errorPages/TechnicalErrorPage.tsx';
import { PageNotFoundPage } from '../errorPages/PageNotFoundPage.tsx';

export const Router = () => {
  return (
    <Switch>
      <Route path="/create" component={TrailEditorPage} />
      <Route path="/profile/:userId" nest component={ProfilePage} />
      <Route path="/view" nest component={TrailViewPage} />
      <Route path="/technical-error" nest component={TechnicalErrorPage} />
      <Route path="/">
        <Redirect to="/view" />
      </Route>
      <Route component={PageNotFoundPage} />
    </Switch>
  );
};
