import { FC, PropsWithChildren } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export const Authenticated: FC<PropsWithChildren> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  return isLoggedIn ? children : null;
};

export const NotAuthenticated: FC<PropsWithChildren> = ({ children }) => {
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  return !isLoggedIn ? children : null;
};
