import { TrailsMapStoreProvider } from '../../shared/mapStoreProvider';
import { TrailsMap } from '../trailMap/TrailsMap';
import { MapDebugInfo } from '../debug/MapDebugInfo';
import { LeftSideBar } from '../leftSideBar/LeftSideBar';

export const TrailViewPage = () => {
  return (
    // TODO: find better solution to replace calc function
    <div className="flex flex-1 h-[calc(100vh-64px)]">
      <LeftSideBar />
      <div className="flex-1 z-0">
        <TrailsMapStoreProvider>
          <TrailsMap />
        </TrailsMapStoreProvider>
        <MapDebugInfo />
      </div>
    </div>
  );
};
