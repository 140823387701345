import { useKeycloak } from '@react-keycloak/web';
import {
  isResponseUnauthorizedBecauseNotLoggedIn,
  UnauthorizedBecauseNotLoggedIn,
} from '../../utils/errors';

export const useFetch = () => {
  const { keycloak } = useKeycloak();

  async function fetchWithAuth(
    input: string | URL | globalThis.Request,
    init?: RequestInit,
  ): Promise<Response> {
    const headers = new Headers(init?.headers);
    headers.set('Authorization', `Bearer ${keycloak.token}`);
    const response = await fetch(input, {
      ...init,
      headers,
    });
    if (isResponseUnauthorizedBecauseNotLoggedIn(response))
      throw UnauthorizedBecauseNotLoggedIn;

    return response;
  }

  return fetchWithAuth;
};
