import { create } from 'zustand';
import { LatLngTuple, LayerGroup, layerGroup } from 'leaflet';
import { createSelectors } from '../../../utils/createSelectors.ts';
import { CreateTrailInput } from '../../../models/CreateTrailInput.ts';

export type CreateTrailFormModel = Pick<
  CreateTrailInput,
  'name' | 'difficultyLevel'
>;

type EditorPathState = {
  pathPointsLayerGroup: LayerGroup;
  setPathPointsLayerGroup: (pathPointsLayerGroup: LayerGroup) => void;
  pathPoints: LatLngTuple[];
  addPointToPath: (pathPoint: LatLngTuple) => void;
  removePointFromPath: (pathPoint: LatLngTuple) => void;
  resetPath: () => void;
  formValue: CreateTrailFormModel;
  setFormValue: (formValue: CreateTrailFormModel) => void;
  formValid: boolean;
  setFormValid: (formValid: boolean) => void;
};

const store = create<EditorPathState>((set) => ({
  pathPointsLayerGroup: layerGroup([]),
  setPathPointsLayerGroup: (pathPointsLayerGroup: LayerGroup) =>
    set({ pathPointsLayerGroup }),
  pathPoints: [],
  addPointToPath: (pathPoint: LatLngTuple) =>
    set((state) => ({ pathPoints: [...state.pathPoints, pathPoint] })),
  removePointFromPath: (pathPoint: LatLngTuple) =>
    set((state) => ({
      pathPoints: state.pathPoints.filter((point) => point !== pathPoint),
    })),
  resetPath: () => set({ pathPoints: [] }),
  formValue: { name: '', difficultyLevel: 0 },
  setFormValue: (formValue: CreateTrailFormModel) => set({ formValue }),
  formValid: false,
  setFormValid: (formValid: boolean) => set({ formValid }),
}));

export const useEditorPathStore = createSelectors(store);
