import { FC, PropsWithChildren, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { preventPropagation } from '../../utils/misc.ts';
import { Icon } from './Icon.tsx';
import { Button } from './Button.tsx';

type DialogConfig = {
  message: string;
  yesButton?: boolean;
  noButton?: boolean;
};

type ConfirmDialogProps = PropsWithChildren<{
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
  closeable?: boolean;
  onClose?: () => void;
  config?: DialogConfig;
}>;

type ConfirmDialogPortalProps = PropsWithChildren<{ wrapperId?: string }>;

const ConfirmDialogPortal: FC<ConfirmDialogPortalProps> = ({
  children,
  wrapperId = 'confirm-dialog-root',
}) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
    null,
  );

  useLayoutEffect(() => {
    const element = document.getElementById(wrapperId)!;
    setWrapperElement(element);
  }, [wrapperId]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};

const ConfirmDialogShell: FC<ConfirmDialogProps> = ({
  children,
  closeable,
  onClose,
  onConfirm,
  onReject,
  config,
}) => {
  const { t } = useTranslation();
  const { message, yesButton, noButton } = config ?? {};

  return (
    <div className="absolute top-0 left-0 h-full w-full bg-opacity-20 bg-neutral-800 flex flex-col justify-center items-center z-30">
      <div onClick={preventPropagation} className="bg-white rounded-lg p-8">
        {closeable && (
          <div className="flex justify-end">
            <button onClick={onClose}>
              <Icon iconName="Close" />
            </button>
          </div>
        )}
        <div className="max-h-[60vh] max-w-[60vw] min-h-12 min-w-64 flex justify-center items-center">
          {children ?? <div className="text-lg">{message}</div>}
        </div>
        <div className="flex justify-between mt-4">
          <Button className="w-24" onClick={onReject}>
            {noButton ?? t('GENERAL.no')}
          </Button>
          <Button className="w-24" onClick={onConfirm}>
            {yesButton ?? t('GENERAL.yes')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  if (!props.isOpen) {
    return null;
  }

  return (
    <ConfirmDialogPortal>
      <ConfirmDialogShell {...props}>{props.children}</ConfirmDialogShell>
    </ConfirmDialogPortal>
  );
};
