import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchTrailById } from '../../hooks/trail/useFetchTrailById.ts';
import { InfiniteLoader } from '../shared/InfiniteLoader.tsx';
import { TrailComments } from './TrailComments.tsx';
import trailImg from '../../assets/mock/trail_placeholder_img.jpg';
import { AddCommentForm } from './AddCommentForm.tsx';
import { TrailPreviewDetails } from '../trailPreview/TrailPreviewDetails.tsx';
import { useFavoriteHook } from '../../hooks/favoriteTrails/useFavoriteHook.ts';
import { TrailPreviewDTO } from '../../__generated__/HikeupApi.ts';
import { useTrailDrawPath } from '../../hooks/trail/useTrailDrawPath.ts';

type TrailDetailsProps = {
  id: number;
  onShowPathClick: () => void;
};

export const TrailDetails: FC<TrailDetailsProps> = ({
  id,
  onShowPathClick,
}) => {
  const { t } = useTranslation();
  const { data: trail } = useFetchTrailById(id);
  const drawPath = useTrailDrawPath(trail);
  const favoriteHook = useFavoriteHook(false); // todo(@Danielduel): get fav data to here

  const showPath = useCallback(() => {
    drawPath();
    onShowPathClick();
  }, [drawPath, onShowPathClick]);

  if (!trail) {
    return (
      <div className="m-10">
        <InfiniteLoader />
      </div>
    );
  }

  return (
    <div>
      <div className="flex p-8">
        <div className="flex-1">
          <TrailPreviewDetails
            favoriteHook={favoriteHook}
            trailPreview={trail as unknown as Required<TrailPreviewDTO>} // todo(@Danielduel): unhack require https://app.clickup.com/t/86bz78p79
            onShowOnMap={showPath}
          />
        </div>
        <div className="flex-1">
          <img
            className="rounded-2xl"
            src={trailImg}
            alt={t('TRAIL.trailImgAlt')}
          />
        </div>
      </div>
      <AddCommentForm trailId={trail.id} />
      <TrailComments trailId={trail.id} />
    </div>
  );
};
