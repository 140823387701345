import { FC, useEffect } from 'react';
import L from 'leaflet';
import { useEditorMapStore } from '../../store/trailsMapStore.ts';
import { useDrawPath } from '../../hooks/useDrawPath.ts';
import { mapEditorPolyline } from '../../shared/mapPolylines.ts';
import { useMap } from '../../hooks/useMap.ts';
import { TrailGeoDetails } from '../../models/TrailGeoDetails.ts';
import { useEditorPathStore } from './store/editorPathStore.ts';

type TrailEditorMapProps = {
  trailGeoDetails?: TrailGeoDetails;
};

export const TrailEditorMap: FC<TrailEditorMapProps> = ({
  trailGeoDetails,
}) => {
  const { mapContainer, map } = useMap();
  const { drawPath, clearPath } = useDrawPath(mapEditorPolyline);
  const { pathPoints } = useEditorPathStore();
  const { currentPath } = useEditorMapStore();

  useEffect(() => {
    if (!pathPoints.length && map && currentPath) {
      map.removeLayer(currentPath);
    }
  }, [currentPath, map, pathPoints.length]);

  useEffect(() => {
    if (trailGeoDetails?.coordinates) {
      const previousPathLength = currentPath?.getLatLngs().length;
      const coordinates = trailGeoDetails.coordinates;
      const newPathLength = L.polyline(coordinates).getLatLngs().length;
      if (newPathLength !== previousPathLength) {
        drawPath(coordinates);
      }
    } else {
      clearPath();
    }
  }, [currentPath, trailGeoDetails, drawPath, clearPath]);

  return <div className="flex-1 z-10" ref={mapContainer}></div>;
};
