export type UserActivity = {
  id: number;
  trailName: string;
  completedOn: string;
  duration: number;
};

export const USER_ACTIVITY_MOCK: UserActivity[] = [
  {
    id: 1,
    trailName: 'Szlak im. Jana Pawła II',
    completedOn: new Date().toISOString(),
    duration: 77800,
  },
  {
    id: 2,
    trailName: 'Szlak im. Jana Pawła II',
    completedOn: new Date().toISOString(),
    duration: 77800,
  },
  {
    id: 3,
    trailName: 'Szlak im. Jana Pawła II',
    completedOn: new Date().toISOString(),
    duration: 77800,
  },
  {
    id: 4,
    trailName: 'Szlak im. Jana Pawła II',
    completedOn: new Date().toISOString(),
    duration: 77800,
  },
];
