import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../assets/i18n/en.json';
import plTranslation from '../assets/i18n/pl.json';

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: true,
  resources: {
    en: {
      translation: enTranslation,
    },
    pl: {
      translation: plTranslation,
    },
  },
});
