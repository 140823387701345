import { PolylineOptions } from 'leaflet';

export const trailPreviewPolyline: PolylineOptions = {
  weight: 8,
  color: 'green',
  stroke: true,
  lineCap: 'round',
  className: 'animate-dash-array-appear',
};

export const mapEditorPolyline: PolylineOptions = {
  weight: 4,
  color: 'red',
  lineCap: 'round',
};
