import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';

import '@fontsource/inter';
import 'leaflet/dist/leaflet.css';
import '@smastrom/react-rating/style.css';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
