/* eslint-disable react-refresh/only-export-components */

import { navigate, useSearch } from 'wouter/use-browser-location';
import { Modal } from '../shared/Modal';
import { FC, useMemo } from 'react';
import { TrailDetails } from './TrailDetails';

type TrailDetailsModalInnerProps = {
  id: number;
};

export const closeTrailDetailsModal = () => {
  const queryParams = new URLSearchParams(location.search);
  queryParams.delete('modal');
  queryParams.delete('modal_id');

  navigate(`${location.pathname}?${queryParams}${location.hash}`);
};

export const openTrailDetailsModal = (modalId: number) => {
  const queryParams = new URLSearchParams(location.search);
  queryParams.set('modal', 'trail');
  queryParams.set('modal_id', `${modalId}`);
  navigate(`${location.pathname}?${queryParams}${location.hash}`);
};

const TrailDetailsModalInner: FC<TrailDetailsModalInnerProps> = ({ id }) => {
  const closeModal = () => closeTrailDetailsModal();

  return (
    <Modal onClose={closeModal} closeable={true}>
      <TrailDetails
        id={id}
        onShowPathClick={() => {
          closeModal();
        }}
      />
    </Modal>
  );
};

export const TrailDetailsModal: FC = () => {
  const queryString = useSearch();
  const queryObject = useMemo(
    () => new URLSearchParams(queryString),
    [queryString],
  );
  const modalData = useMemo(
    () => ({
      type: queryObject.get('modal'),
      id: queryObject.get('modal_id'),
    }),
    [queryObject],
  );

  if (
    modalData.type !== 'trail' ||
    !modalData.id ||
    !Number.parseInt(modalData.id)
  ) {
    return null;
  }

  const id = Number(modalData.id);

  return <TrailDetailsModalInner id={id} />;
};
