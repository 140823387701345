import {
  type FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import classNames from 'classnames';
import { useTrailsMapStore } from '../../store/trailsMapStore.ts';
import { useLeftSideBarStore } from '../../store/leftSideBarStore.ts';
import { Content } from './content/Content.tsx';
import { TopMenu } from './menu/topMenu/TopMenu.tsx';
import { useResetErrorBoundaryRef } from '../errorBoundary/ErrorBoundary.tsx';
import { ExpandButton } from './ExpandButton.tsx';
import { useBrowserLocation } from 'wouter/use-browser-location';

export const LeftSideBar: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { map } = useTrailsMapStore();
  const { expanded, setExpanded } = useLeftSideBarStore();
  const [path] = useBrowserLocation();
  const contentErrorBoundaryRefState = useResetErrorBoundaryRef();

  useEffect(() => {
    contentErrorBoundaryRefState.resetErrorBoundaryRef.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useLayoutEffect(() => {
    const scopedContainer = containerRef.current;
    if (!scopedContainer) return;

    const invalidateMapSize = () => {
      map?.invalidateSize({
        animate: true,
        pan: false,
      });
    };
    scopedContainer.addEventListener('transitionend', invalidateMapSize);
    return () => {
      scopedContainer.removeEventListener('transitionend', invalidateMapSize);
    };
  }, [map, containerRef, expanded]);

  useLayoutEffect(() => {
    map?.invalidateSize({
      animate: true,
      pan: false,
    });
  }, [expanded, map]);

  const toggleExpanded = useCallback(
    () => setExpanded(!expanded),
    [setExpanded, expanded],
  );

  return (
    <div
      ref={containerRef}
      className={classNames(
        'h-full max-h-full bg-neutral-100 z-10 flex flex-col',
        {
          'min-w-96 w-1/4': expanded,
          'min-w-0 w-12': !expanded,
        },
      )}
    >
      <div className="flex h-full">
        <div className="w-12 min-w-12 max-w-12 h-full bg-neutral-200 flex flex-col">
          <ExpandButton expanded={expanded} onToggle={toggleExpanded} />
          <TopMenu />
        </div>
        <div className="w-full overflow-y-scroll">
          {expanded && <Content {...contentErrorBoundaryRefState} />}
        </div>
      </div>
    </div>
  );
};
