import { ProfileData } from '../models/ProfileData.ts';

export const PROFILE_DATA_MOCK: ProfileData = {
  id: 5,
  fullName: 'Marcin Kubiak',
  description:
    'My name is Marcin. I like outdoor activity. Hiking takes up every free moment I have. I prefer paper maps, which never dissapoint.',
  lastActivity: Date.now().toString(),
  achievements: ['A1', 'A2', 'A3', 'A4', 'A5'],
  badge: 'Mountain Wanderer',
  userLocation: 'Wrocław, Poland',
};
