import { useQuery } from '@tanstack/react-query';
import { HikeupApi } from '../../shared/hikeup-api.ts';

export type TrailsSearchParams = {
  name?: string;
  min_length?: number;
  max_length?: number;
  min_duration?: number;
  max_duration?: number;
  difficulty_level?: number;
  rate?: number;
};

export const useFetchTrails = (params: TrailsSearchParams | null) => {
  return useQuery({
    queryKey: ['trails', params],
    enabled: Boolean(params),
    queryFn: async () => {
      if (params === null) {
        return [];
      }

      const response = await HikeupApi.trailController.findAll(params, {
        format: 'json',
      });
      return response.data;
    },
  });
};
