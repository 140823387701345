import {
  TrailsSearchParams,
  useFetchTrails,
} from '../../hooks/trail/useFetchTrails.ts';
import { TrailListItem } from '../shared/TrailListItem.tsx';
import { useState } from 'react';
import { InfiniteLoader } from '../shared/InfiniteLoader.tsx';
import { useTranslation } from 'react-i18next';
import { Heading2 } from '../shared/Heading.tsx';
import { SearchFilters } from './SearchFilters.tsx';
import { TrailPreviewDTO } from '../../__generated__/HikeupApi.ts';

type ListOfTrailsProps = {
  trails: TrailPreviewDTO[] | undefined;
  isFetching: boolean;
};

const ListOfTrails = (props: ListOfTrailsProps) => {
  const { t } = useTranslation();

  if (props.isFetching) {
    return <InfiniteLoader />;
  }

  if (!props.trails) {
    return null;
  }

  if (!props.trails.length) {
    return (
      <div className="text-center m-8 font-bold">{t('GENERAL.noResults')}</div>
    );
  }

  return (
    <div>
      {props.trails.map((item) => (
        <TrailListItem key={item.id} trail={item} />
      ))}
    </div>
  );
};

export const TrailsSearch = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useState<TrailsSearchParams | null>(
    null,
  );
  const { data, isFetching } = useFetchTrails(searchParams);

  return (
    <div className="m-8">
      <Heading2>{t('TRAIL.searchTrails')}</Heading2>
      <SearchFilters onFiltersSelect={(data) => setSearchParams(data)} />
      <ListOfTrails trails={data} isFetching={isFetching} />
    </div>
  );
};
