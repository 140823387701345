import {
  createElement,
  type ReactHTML,
  type HTMLAttributes,
  type DetailedHTMLProps,
  type FC,
} from 'react';
import classNames from 'classnames';
import type * as MUI_ICONS from '@mui/icons-material';

export type IconName = keyof typeof MUI_ICONS;

type IconTagNames = Pick<ReactHTML, 'div' | 'span'>;
type IconPropsMap = {
  div: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  span: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
};

type IconProps<TagName extends keyof IconPropsMap> = {
  baseTag: TagName;
  iconName: IconName;
} & IconPropsMap[TagName];

type IconPropsDefaultCase = Omit<IconProps<'span'>, 'baseTag'> & {
  baseTag?: undefined;
};
type IconPropsUnion =
  | IconProps<'div'>
  | IconProps<'span'>
  | IconPropsDefaultCase;

const transformIconName = (iconName: IconName): string => {
  // it is necessary, because the union is like "AccessTimeOutlined" and it has to be "access_time_outlined"
  // Using adapted method from here:
  // https://stackoverflow.com/a/54246501/5694206
  const str = iconName.replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`,
  );
  if (str.startsWith('_')) return str.substring(1);
  return str;
};

const baseClassName = 'material-icons';
const createGenericIcon = <TagName extends keyof IconTagNames>(
  tagName: TagName,
  _iconName: IconName,
  { iconName, baseTag, ...props }: IconProps<TagName>,
) =>
  createElement(
    tagName,
    { ...props, className: classNames(baseClassName, props.className) },
    transformIconName(_iconName),
  );

const InlineIcon: FC<IconProps<'span'>> = (props) =>
  createGenericIcon('span', props.iconName, props);
const BlockIcon: FC<IconProps<'div'>> = (props) =>
  createGenericIcon('div', props.iconName, props);

export const Icon: FC<IconPropsUnion> = (props) => {
  if (!props.baseTag) {
    return <InlineIcon {...props} baseTag="span" />;
  }
  switch (props.baseTag) {
    case 'div':
      return <BlockIcon {...props} />;
    case 'span':
      return <InlineIcon {...props} />;
  }
};
