import { SubmitHandler, useForm } from 'react-hook-form';
import { FC, useCallback } from 'react';
import { Button } from '../shared/Button.tsx';
import { useTranslation } from 'react-i18next';
import { TrailsSearchParams } from '../../hooks/trail/useFetchTrails.ts';

export type TrailsSearchFormModel = {
  name: string;
  minLength: string;
  maxLength: string;
  minDuration: string;
  maxDuration: string;
  difficultyLevel: string;
  rate: string;
};

type SearchFiltersProps = {
  onFiltersSelect: (filters: TrailsSearchParams) => void;
};

export const SearchFilters: FC<SearchFiltersProps> = ({ onFiltersSelect }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<TrailsSearchFormModel>();

  const onSubmit: SubmitHandler<TrailsSearchFormModel> = useCallback(
    (data) => {
      const searchParams: TrailsSearchParams = {};
      if (data.name) {
        searchParams.name = data.name;
      }
      if (data.minLength) {
        searchParams.min_length = Number(data.minLength);
      }
      if (data.maxLength) {
        searchParams.max_length = Number(data.maxLength);
      }
      if (data.minDuration) {
        searchParams.min_duration = Number(data.minDuration);
      }
      if (data.maxDuration) {
        searchParams.max_duration = Number(data.maxDuration);
      }
      if (data.difficultyLevel) {
        searchParams.difficulty_level = Number(data.difficultyLevel);
      }
      if (data.rate) {
        searchParams.rate = Number(data.rate);
      }
      onFiltersSelect(searchParams);
    },
    [onFiltersSelect],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col">
        <span>{t('TRAIL.searchFilters.name')}:</span>
        <input
          className="my-2"
          type="text"
          placeholder={t('GENERAL.searchPlaceholder')}
          {...register('name')}
        />
        <span>{t('TRAIL.searchFilters.minLength')}:</span>
        <input className="my-2" type="number" {...register('minLength')} />
        <span>{t('TRAIL.searchFilters.maxLength')}:</span>
        <input className="my-2" type="number" {...register('maxLength')} />
        <span>{t('TRAIL.searchFilters.minDuration')}:</span>
        <input className="my-2" type="number" {...register('minDuration')} />
        <span>{t('TRAIL.searchFilters.maxDuration')}:</span>
        <input className="my-2" type="number" {...register('maxDuration')} />
        <span>{t('TRAIL.searchFilters.difficultyLevel')}:</span>
        <input
          className="my-2"
          type="number"
          {...register('difficultyLevel')}
        />
        <span>{t('TRAIL.searchFilters.rate')}:</span>
        <input className="my-2" type="number" {...register('rate')} />
        <Button className="my-4" type="submit">
          {t('GENERAL.search')}
        </Button>
      </div>
    </form>
  );
};
