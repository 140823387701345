import { type FC, useCallback, useState } from 'react';
import { TrailBasicInfo } from './TrailBasicInfo.tsx';
import { TrailPreviewDTO } from '../../__generated__/HikeupApi.ts';
import { useFetchTrailById } from '../../hooks/trail/useFetchTrailById.ts';
import { InfiniteLoader } from './InfiniteLoader.tsx';
import { useTranslation } from 'react-i18next';
import { useTrailDrawPath } from '../../hooks/trail/useTrailDrawPath.ts';

type TrailListItemProps = {
  trail: TrailPreviewDTO;
};

const TrailListItemPreview: FC<TrailListItemProps> = ({ trail }) => {
  return <div className="font-bold text-lg">{trail.name}</div>;
};

const TrailListItemExpanded: FC<TrailListItemProps> = ({ trail }) => {
  const {
    data: trailDetails,
    isLoading,
    isFetching,
  } = useFetchTrailById(trail.id!);
  const drawPath = useTrailDrawPath(trailDetails);
  const { t } = useTranslation();
  const _drawPath = useCallback(() => drawPath(), [drawPath]);

  if (isLoading)
    return (
      <>
        <TrailListItemPreview trail={trail} />
        <InfiniteLoader />
      </>
    );
  if (!trailDetails)
    return (
      <>
        <TrailListItemPreview trail={trail} />
        {t('TRAIL.fetchDetailsForPreviewError')}
      </>
    );

  return (
    <TrailBasicInfo
      trail={trail as Required<TrailPreviewDTO>} // todo(@Danielduel): unhack require https://app.clickup.com/t/86bz78p79
      onShowOnMap={_drawPath}
      isFetching={isFetching}
    />
  );
};

export const TrailListItem: FC<TrailListItemProps> = ({ trail }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(
    () => setExpanded(!expanded),
    [setExpanded, expanded],
  );

  return (
    <div
      className="p-4 my-6 border-solid border-2 border-gray-800 hover:shadow-lg hover:shadow-emerald-700"
      onClick={toggleExpanded}
    >
      {expanded ? (
        <TrailListItemExpanded trail={trail} />
      ) : (
        <TrailListItemPreview trail={trail} />
      )}
    </div>
  );
};
