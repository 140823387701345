import { useSuspenseQuery } from '@tanstack/react-query';
import { useSuspenseKeycloak } from '../keycloak/useSuspenseKeycloak.ts';
import { HikeupApi } from '../../shared/hikeup-api.ts';

export const useSuspenseFetchUserFavoriteTrails = () => {
  const [{ keycloak }] = useSuspenseKeycloak();
  return useSuspenseQuery({
    queryKey: ['userTrails'],
    queryFn: async () => {
      const response =
        await HikeupApi.userFavoriteController.findUserFavoriteTrails({
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
          format: 'json',
        });
      return response.data;
    },
  });
};
