import { type FC, type MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { type TrailPreviewDTO } from '../../__generated__/HikeupApi';
import { type FavoriteHook } from '../../hooks/favoriteTrails/useFavoriteHook.ts';
import { Heading1 } from '../shared/Heading';
import { DifficultyLevel } from '../shared/DifficultyLevel';
import { TrailRating } from '../shared/TrailRating';
import { Button } from '../shared/Button';
import {
  distanceInKm,
  durationInHours,
  formatDate,
} from '../../utils/formatter.ts';
import { ShareTrailButton } from '../trailDetails/ShareTrailButton.tsx';

export type TrailPreviewDetailsProps = {
  favoriteHook: FavoriteHook;
  trailPreview: Required<TrailPreviewDTO>; // todo(@Danielduel): unhack require https://app.clickup.com/t/86bz78p79
  onShowOnMap: MouseEventHandler<HTMLElement>;
};

export const TrailPreviewDetails: FC<TrailPreviewDetailsProps> = ({
  favoriteHook,
  trailPreview,
  onShowOnMap,
}) => {
  const { t } = useTranslation();
  const { name, difficultyLevel, distance, duration, id, rate } = trailPreview;
  const { isFavorite, addToFavorites, removeFromFavorites } = favoriteHook;
  const createdAt = (trailPreview as any).createdAt; // todo(@Danielduel): remove as any after model fix

  return (
    <div className="flex-1">
      <Heading1>
        {name} <ShareTrailButton />
      </Heading1>
      {isFavorite && (
        <div className="my-2 font-bold">({t('TRAIL.trailOnList')})</div>
      )}
      <small>{`${t('TRAIL.createdAt')}: ${formatDate(createdAt)}`}</small>
      <div>{`${t('TRAIL.distance')}: ${distanceInKm(distance)}`}</div>
      <div>{`${t('TRAIL_EDITOR.duration')}: ${durationInHours(duration)}`}</div>
      <div>
        {t('TRAIL.difficultyLevel')}:&nbsp;
        <DifficultyLevel value={difficultyLevel} readOnly />
      </div>
      <div className="mb-2">
        {t('TRAIL.rate')}:&nbsp;
        <TrailRating value={rate ?? 0} readOnly />
      </div>
      <div className="flex flex-col items-start *:mb-4">
        <Button onClick={onShowOnMap}>{t('TRAIL.showOnMap')}</Button>
        {!isFavorite && (
          <Button onClick={() => addToFavorites(id)}>
            {t('TRAIL.addToFavorites')}
          </Button>
        )}
        {isFavorite && (
          <Button onClick={() => removeFromFavorites(id)}>
            {t('TRAIL.removeFromFavorites')}
          </Button>
        )}
      </div>
    </div>
  );
};
