import classNames from 'classnames';
import { createElement, type FC, type HTMLAttributes } from 'react';

export const Heading1: FC<HTMLAttributes<HTMLHeadingElement>> = (props) =>
  createElement('h1', {
    ...props,
    className: classNames('text-3xl mb-3', props.className),
  });

export const Heading2: FC<HTMLAttributes<HTMLHeadingElement>> = (props) =>
  createElement('h2', {
    ...props,
    className: classNames('text-2xl mb-2', props.className),
  });

export const Heading3: FC<HTMLAttributes<HTMLHeadingElement>> = (props) =>
  createElement('h3', {
    ...props,
    className: classNames('text-xl mb-1.5', props.className),
  });

export const Heading4: FC<HTMLAttributes<HTMLHeadingElement>> = (props) =>
  createElement('h4', {
    ...props,
    className: classNames('text-lg mb-1', props.className),
  });
