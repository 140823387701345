import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetch } from '../baseHooks/useFetch.ts';
import { useToast } from '../baseHooks/useToast.ts';

export const useRemoveTrailFromFavorites = () => {
  const { successToast, errorToast } = useToast();
  const queryClient = useQueryClient();
  const fetchWithAuth = useFetch();

  return useMutation({
    mutationFn: async (trailId: number) => {
      const response = await fetchWithAuth(
        `/hikeup/api/v1/user/favorite/${trailId}`,
        { method: 'DELETE' },
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['userTrails'] });
      successToast('TRAIL.removeFromFavoritesSuccess');
    },
    onError: (error: Error) => {
      console.log(error);
      errorToast('TRAIL.removeFromFavoritesError');
    },
  });
};
