import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading2 } from '../shared/Heading.tsx';

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(i18n.language);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setValue(event.target.value);
      i18n.changeLanguage(event.target.value);
    },
    [i18n],
  );

  return (
    <div className="my-4 mx-8">
      <Heading2>{t('TOP_BAR.settings.languageHeader')}</Heading2>
      <div>{t('TOP_BAR.settings.selectLanguage')}</div>
      <select id="lang" onChange={onChange} value={value}>
        <option value="en">English</option>
        <option value="pl">polski</option>
      </select>
    </div>
  );
};
