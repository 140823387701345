import { type FC, useMemo } from 'react';
import { Rating, RoundedStar, type RatingProps } from '@smastrom/react-rating';

const _style = { maxWidth: 180 };
const _itemStyles = {
  itemShapes: RoundedStar,
  activeFillColor: ['#19a974', '#61bb00', '#ffde37', '#ff6300', '#e7040f'],
  inactiveFillColor: '#C7C7C7',
};

export const DifficultyLevel: FC<RatingProps> = (props) => {
  const style = useMemo(
    () => ({
      ..._style,
      ...props.style,
    }),
    [props.style],
  );
  const itemStyles = useMemo(
    () => ({
      ..._itemStyles,
      ...props.itemStyles,
    }),
    [props.itemStyles],
  );
  return (
    <Rating
      spaceBetween="small"
      {...props}
      style={style}
      itemStyles={itemStyles}
    />
  );
};
